import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { MenuItem, Select } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

const useStyles = makeStyles({
  select: {
    color: '#4896F4 !important',
  },
  icon: {
    fill: '#4896F4 !important',
  },
});

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
  onRowsPerPageChange: (value: number) => void;
}

export default function OfferTablePagination(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const classes = useStyles();
  const { count, page, rowsPerPage, onPageChange, onRowsPerPageChange } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const pagesNumbers = Array.from({ length: Math.ceil(count / rowsPerPage) }, (_, index) => index + 1);

  const previousButtonDisabled = page === 0;
  const nextButtonDisabled = page >= Math.ceil(count / rowsPerPage) - 1;

  return (
    <div className="flex w-full p-2">
      <IconButton onClick={handleBackButtonClick} disabled={previousButtonDisabled} aria-label="previous page">
        <div className={`text-sm font-bold ${!previousButtonDisabled && 'text-cyan-600'}`}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          <span>Zurück</span>
        </div>
      </IconButton>
      <div className="flex justify-center items-center space-x-1">
        {pagesNumbers.map((n) => {
          const isSelectedStyle = 'border rounded border-cyan-500 bg-cyan-50';
          return <div className={`pl-2 pr-2 pt-1 pb-1 font-bold text-sm cursor-pointer ${page + 1 === n && isSelectedStyle}`}>{n}</div>;
        })}
      </div>
      <IconButton onClick={handleNextButtonClick} disabled={nextButtonDisabled} aria-label="next page">
        <div className={`text-sm font-bold ${!nextButtonDisabled && 'text-cyan-600'}`}>
          <span>Weiter</span>
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </div>
      </IconButton>
      <Select
        labelId="pagination-select"
        id="pagination-select-id"
        value={rowsPerPage}
        onChange={(e) => onRowsPerPageChange(parseInt(e.target.value.toString()))}
        autoWidth
        label="Age"
        className={classes.select}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}>
        <MenuItem value={5}>5 pro Seite</MenuItem>
        <MenuItem value={25}>25 pro Seite</MenuItem>
        <MenuItem value={50}>50 pro Seite</MenuItem>
      </Select>
    </div>
  );
}
