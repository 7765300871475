import { Avatar } from '@mui/material';
import { SalesRep } from '../../shared/interfaces/offer.interface';

interface OfferSummaryContactProps {
  contact?: SalesRep;
  showSalesManager?: boolean;
}

export const OfferSummaryContact: React.FC<OfferSummaryContactProps> = ({ contact, showSalesManager }) => {
  return (
    <div className="flex flex-col mt-16 space-y-1">
      <span className="font-bold mb-2">Für Rückfragen stehen wir gerne jederzeit zur Verfügung.</span>
      {showSalesManager && (
        <>
          {contact?.vystemUser?.avatar && <Avatar src={`https://static.vystem.io/${contact.vystemUser.avatar}`} />}
          {contact?.name && <div>{contact.name}</div>}
          {contact?.role && <div>{contact.role}</div>}
          {contact?.email && <div>{contact.email}</div>}
          {contact?.phone && <div>{contact.phone}</div>}
        </>
      )}
      {!showSalesManager && (
        <>
          <div>hello@vystem.io</div>
          <div>+49 8041 4390360</div>
        </>
      )}
      <div style={{ marginTop: '2rem' }}>Stuzubi GmbH</div>
      <div>Dr.-Johann-Heitzer-Straße 2</div>
      <div>85757 Karlsfeld</div>
    </div>
  );
};
