import { format } from 'date-fns';
import { PopulatedOffer } from '../../shared/interfaces/offer.interface';

interface OfferMetaDataProps {
  offer?: PopulatedOffer;
}

export const OfferMetaData: React.FC<OfferMetaDataProps> = (props) => {
  const { offer } = props;

  if (!offer) return null;

  return (
    <div className="flex flex-col text-right space-y-1">
      <div className="font-bold">Referenz: {offer.offerReferenceNumber}</div>
      {offer.datetime_created && <div>Angebot erstellt: {format(new Date(offer.datetime_created), 'dd.MM.yyyy')}</div>}
      {offer.datetime_expiry && <div>Angebot läuft ab: {format(new Date(offer.datetime_expiry), 'dd.MM.yyyy')}</div>}
      {offer.showSalesManager && (
        <>
          {offer.hubspotMetaData?.owner?.name && <div>Angebot erstellt von: {offer.hubspotMetaData.owner.name}</div>}
          {offer.hubspotMetaData?.owner?.role && <div>{offer.hubspotMetaData.owner.role}</div>}
          {offer.hubspotMetaData?.owner?.email && <div>{offer.hubspotMetaData.owner.email}</div>}
          {offer.hubspotMetaData?.owner?.phone && <div>{offer.hubspotMetaData.owner.phone}</div>}
        </>
      )}
    </div>
  );
};
