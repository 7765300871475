import { Button, Dialog, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createPosition, updatePosition } from '../../../services';
import { OfferPosition, OfferSection } from '../../../shared/interfaces/offer.interface';
import { BooleanFormField } from '../../shared/offer/BooleanFormField';

interface CreatePositionModalProps {
  sections: OfferSection[];
  editPosition?: OfferPosition;
  onClose: () => void;
  onCreate: (position: OfferPosition) => void;
}

export const CreatePositionModal: React.FC<CreatePositionModalProps> = (props) => {
  const { onClose, onCreate, sections, editPosition } = props;
  const { register, handleSubmit, control, reset } = useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    reset({
      ...editPosition,
    });
  }, [isModalOpen]);

  useEffect(() => {
    if (editPosition) setIsModalOpen(true);
  }, [editPosition]);

  async function submit(data: OfferPosition) {
    if (editPosition?._id) {
      await updatePosition(editPosition._id, data);
    } else {
      await createPosition(data);
    }
    onCreate(data);
    setIsModalOpen(false);
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
        Position hinzufügen
      </Button>
      <Dialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          onClose();
        }}>
        <form className="flex flex-col p-3 space-y-3" onSubmit={handleSubmit(submit)}>
          Position hinzufügen
          <TextField {...register('label', { required: true })} id="outlined-basic" label="Name" variant="outlined" />
          <TextField {...register('description')} name="description" id="outlined-basic" label="Beschreibung" variant="outlined" />
          <Controller
            control={control}
            name="sectionId"
            rules={{ required: true }}
            render={({ field: { onChange, value, name } }) => (
              <FormControl fullWidth>
                <InputLabel id="select-section">Sektion</InputLabel>
                <Select value={value} onChange={(e) => onChange(e.target.value)} labelId="select-section" id="section-select" label="Sektion">
                  {sections.map((section) => (
                    <MenuItem value={section._id}>{section.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field: { onChange, value, name } }) => (
              <FormControl fullWidth>
                <InputLabel id="type-section">Typ</InputLabel>
                <Select value={value} onChange={(e) => onChange(e.target.value)} labelId="type-section" id="type-select" label="Typ">
                  <MenuItem value={'text'}>Text</MenuItem>
                  <MenuItem value={'boolean'}>Checkbox</MenuItem>
                  <MenuItem value={'number'}>Nummer</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="pricingType"
            render={({ field: { onChange, value, name } }) => (
              <FormControl fullWidth>
                <InputLabel id="type-section">Preistyp</InputLabel>
                <Select value={value} onChange={(e) => onChange(e.target.value)} labelId="type-section" id="type-select" label="Typ">
                  <MenuItem value={'fixed'}>Fixpreis</MenuItem>
                  <MenuItem value={'pricePerAttendee'}>Preis pro Teilnehmer und Anzahl</MenuItem>
                  <MenuItem value={'variable'}>Preis pro Anzahl</MenuItem>
                  <MenuItem value={'variableTemplate'}>Preis pro Anzahl und Teilnehmer (Templateabhängig)</MenuItem>
                  <MenuItem value={'variableTemplateFixPrice'}>Fixpreis (Templateabhängig)</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <TextField {...register('price')} id="outlined-basic" label="Preis" variant="outlined" />
          <BooleanFormField name="isParticipantCountField" control={control} label="Ist Teilnehmeranzahl Feld" />
          <Button variant="contained" type="submit">
            Speichern
          </Button>
        </form>
      </Dialog>
    </>
  );
};
