import { Divider } from '@mui/material';

interface TitleBarProps {
  title: string;
  actions?: JSX.Element;
}

export const TitleBar: React.FC<TitleBarProps> = (props) => {
  const { title, actions } = props;

  return (
    <>
      <div className="flex flex-row justify-between mb-3">
        <div className="text-xl">{title}</div>
        <div className="actions">{actions}</div>
      </div>
      <Divider />
    </>
  );
};
