import { format, isAfter } from 'date-fns';
import { PopulatedOffer } from '../../shared/interfaces/offer.interface';

export const OfferSummaryTerms: React.FC<{ offer?: PopulatedOffer }> = ({ offer }) => {
  if (offer) {
    const lastTermChange = new Date('2023-10-10T08:55:54Z');
    const offerUpdatedAfterTermChange = offer.datetime_updated ? isAfter(new Date(offer.datetime_updated), lastTermChange) : false;
    const offerCreatedAfterTermChange = offer.datetime_created ? isAfter(new Date(offer.datetime_created), lastTermChange) : false;
    if (offerUpdatedAfterTermChange || offerCreatedAfterTermChange || offer.status === 'draft')
      return (
        <div className="flex flex-col mt-8" style={{ pageBreakInside: 'avoid', pageBreakBefore: 'always' }}>
          <span className="font-bold mb-4">Auftragsbedingungen</span>
          <div className="summary-description">
            Alle angegebenen Preise verstehen sich als Netto Preise und gelten zzgl. der gesetzlichen Mehrwertsteuer.
            <br />
            <br />
            Bei keiner gegenteiligen schriftlichen Vereinbarung erfolgt die Rechnungsstellung nach Beauftragung. pay-per-use Leistungen werden jeweils am
            Monatsende zu festgelegten Raten abgerechnet oder mit bestehenden Kontingenten gegengerechnet.
            <br />
            <br />
            Bei keiner gegenteiligen schriftlichen Vereinbarung handelt es sich um einen Laufzeitvertrag der ab Auftragsdatum gültig wird. Die Vertragslaufzeit
            entspricht wahlweise 12 I 24 oder 36 Monate bzw. mindestens 12 Monate mit automatischer Verlängerung bei einer Kündigungsfrist von 3 Monaten vor
            Vertragsende.
            <br />
            <br />
            <br />
            {offer.datetime_license_start ? `Lizenzbeginn: ${format(new Date(offer.datetime_license_start), 'dd.MM.yyyy')}` : 'Lizenzbeginn bei Unterschrift'}
          </div>
        </div>
      );
  }

  return (
    <div className="flex flex-col mt-8" style={{ pageBreakInside: 'avoid', pageBreakBefore: 'always' }}>
      <span className="font-bold mb-4">Kaufbedingungen</span>
      <div className="summary-description">
        Alle angegebenen Preise verstehen sich als Netto Preise und gelten zzgl. der gesetzlichen Mehrwertsteuer.
        <br />
        Bei keiner gegenteiligen schriftlichen Vereinbarung erfolgt die Rechnungsstellung nach Beauftragung. Sollten variable Komponenten im Angebot enthalten
        sein, findet deren Rechnungsstellung am Tag vor der Veranstaltung statt.
        <br />
        Stornierung des Auftrags haben bei mehr als 5 / 3 / 2 Wochen vor der Veranstaltung 25% / 50% / 75% der Gesamtkosten oder bei 2 Wochen oder weniger vor
        der Veranstaltung 100% der Gesamtkosten als Stornokosten zur Folge.
      </div>
    </div>
  );
};
