import { PopulatedOffer } from '../../shared/interfaces/offer.interface';

interface CompanyInfoProps {
  offer?: PopulatedOffer;
}

export const CompanyInfo: React.FC<CompanyInfoProps> = (props) => {
  const { offer } = props;

  if (!offer || !offer.hubspotMetaData || offer.hubspotMetaData.companies?.length === 0) return null;

  const companyData = offer.hubspotMetaData.companies[0];
  const companyContacts = offer.hubspotMetaData.contacts
    .map((c) => {
      if (offer.selectedContactIds?.includes(c.id)) return c;
    })
    .filter((c) => c);

  return (
    <div className="flex flex-col space-y-1">
      <div className="font-bold">{companyData?.name}</div>
      <div>{companyData?.address}</div>
      <div>
        {companyData?.zip} {companyData?.city}
      </div>
      {companyContacts.map((c) => (
        <>
          <br />
          <div>
            {' '}
            {c?.firstname && c.firstname} {c?.lastname && c.lastname}
          </div>
          {c?.jobtitle && <div>{c.jobtitle}</div>}
          {c?.mobilephone && <div>{c.mobilephone}</div>}
          {c?.phone && <div>{c.phone}</div>}
        </>
      ))}
    </div>
  );
};
