import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TitleBar } from '../components/adminLayout/TitleBar';
import { OfferTable } from '../components/offers/OfferTable';
import { AdminLayout } from '../layouts/AdminLayout';

export const OffersPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <AdminLayout>
      <TitleBar
        title="Angebote"
        actions={
          <div className="flex flex-row space-x-2">
            <Button className="mb-3" variant="outlined" onClick={() => window.open('https://app.hubspot.com/contacts/7712979/objects/0-3/views/all/board')}>
              Hubspot Deals öffnen
            </Button>
            <Button className="mb-3" variant="outlined" onClick={() => navigate('/offerbuilder')}>
              Neues Angebot erstellen
            </Button>
          </div>
        }
      />
      <OfferTable />
    </AdminLayout>
  );
};
