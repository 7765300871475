export const VystemStickyHeader: React.FC = () => {
  return (
    <header>
      <div className="print:hidden fixed top-0 bg-white w-full justify-center flex">
        <div className="w-full h-16 py-5" style={{ maxWidth: '210mm' }}>
          <img src="/vystemlogo.png" alt="vystem logo" className="h-full" />
        </div>
      </div>
    </header>
  );
};
