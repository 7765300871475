import { TitleBar } from '../components/adminLayout/TitleBar';
import { CallsPerUser } from '../components/reports/CallsPerUser';
import { AdminLayout } from '../layouts/AdminLayout';

export const DashboardPage: React.FC = () => {
  return (
    <AdminLayout>
      <TitleBar title="Dashboard" />
      <CallsPerUser floatingDays={7} />
      <CallsPerUser floatingDays={30} />
    </AdminLayout>
  );
};
