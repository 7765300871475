import { useEffect } from 'react';
import { useOfferCalculation } from '../../hooks/useOfferCalculation';
import { OfferPosition, OfferSection, OfferTemplate, PopulatedOffer } from '../../shared/interfaces/offer.interface';
import { OfferSummaryTable } from '../offer/OfferSummaryTable';

interface OfferPreviewPops {
  values: Partial<PopulatedOffer>;
  offerPositions: OfferPosition[];
  offerSections: OfferSection[];
  offerTemplates: OfferTemplate[];
}
export const OfferPreview: React.FC<OfferPreviewPops> = (props) => {
  const { values, offerPositions, offerSections, offerTemplates } = props;

  const { calculatePositions, calculatedPositions, calculateTotalPrice } = useOfferCalculation(offerPositions, offerTemplates, values);

  useEffect(() => {
    if (values) calculatePositions(values);
  }, [values]);

  return (
    <div className="w-fit flex flex-col p-5 text-white h-full drop-shadow-2xl rounded-md" style={{ backgroundColor: '#4896F4' }}>
      <OfferSummaryTable
        calculatedPositions={calculatedPositions}
        offerSections={offerSections}
        totalPrice={calculateTotalPrice(calculatedPositions)}
        offer={values}
        detailView={true}
        color="white"
      />
    </div>
  );
};
