import React from 'react';
import { TitleBar } from '../components/adminLayout/TitleBar';
import { PositionTable } from '../components/settings/PositionTable/index';
import { SalesAccessUserTable } from '../components/settings/SalesAccessUserTable';
import { AdminLayout } from '../layouts/AdminLayout';

export const SettingsPage: React.FC = () => {
  return (
    <AdminLayout>
      <TitleBar title="Einstellungen" />
      <PositionTable />
      <SalesAccessUserTable />
    </AdminLayout>
  );
};
