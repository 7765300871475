import { isTokenSet, setToken } from '../helpers/auth';
import { loginService } from '../services';

export function useAuth() {
  function checkAuthFromLocalStorage(): boolean {
    return !!isTokenSet();
  }

  async function login(email?: string, password?: string): Promise<boolean | void> {
    const data = await loginService(email, password);
    const { access_token, role } = data;

    if (access_token) {
      setToken(access_token);
      return true;
    }
    return false;
  }

  return {
    checkAuthFromLocalStorage,
    login,
  };
}
