import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { deleteOffer, duplicateOffer } from '../../services';

const useStyles = makeStyles({
  endIcon: {
    marginLeft: '0 !important',
  },
});

interface OfferTableActionButtonProps {
  offerId?: string;
  onFinish?: () => void;
}

export const OfferTableActionButton: React.FC<OfferTableActionButtonProps> = ({ offerId, onFinish }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function delOffer() {
    if (!offerId) return;

    try {
      await deleteOffer(offerId);
      onFinish?.();
    } catch (err) {
      console.error(err);
    }
  }

  async function duplOffer() {
    if (!offerId) return;

    try {
      await duplicateOffer(offerId);
      onFinish?.();
    } catch (err) {
      console.error(err);
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        className="action-button template-border"
        classes={{
          endIcon: classes.endIcon,
        }}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        style={{ display: open ? 'inline-flex' : 'none' }}>
        Aktionen
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <List className="template-border text-sm">
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(`/offerbuilder/${offerId}`)}>
              <ListItemText primary="Angebotseditor öffnen" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <Link className="w-full" target="_blank" to={`/offer/${offerId}`}>
              <ListItemButton>
                <ListItemText primary="Angebot öffnen" />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => duplOffer()}>
              <ListItemText primary="Angebot duplizieren" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => delOffer()}>
              <ListItemText primary="Löschen" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
