import { AppBar, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Sidebar } from '../components/adminLayout/Sidebar';
import { AuthWrapper } from './AuthWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  appBarShift: {
    padding: 20,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'auto',
    marginTop: 100,
    marginLeft: 240,
  },
}));

export const AdminLayout: React.FC = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <AuthWrapper>
      <div className={classes.root}>
        <main className={classes.content}>
          <Container maxWidth="xl">
            <div className="flex flex-col space-y-3">{children}</div>{' '}
          </Container>
        </main>
        <AppBar color="primary" position="absolute" className={classes.appBarShift}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: '250px' }}>
            vystem Sales
          </Typography>
        </AppBar>
        <Sidebar />
      </div>
    </AuthWrapper>
  );
};
