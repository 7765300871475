import { OfferSection } from '../../shared/interfaces/offer.interface';

/**
 * Workaround necessary as we are in a form fields array
 */
interface SectionTitleProps {
  fields: { sectionId: string; positionId: string; value: any }[];
  currentIndex: number;
  currentFieldSection: string;
  allSections: OfferSection[];
}

export const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  const { fields, currentIndex, currentFieldSection, allSections } = props;

  if (currentIndex !== 0 && fields[currentIndex - 1].sectionId === currentFieldSection) return <></>;

  const section = allSections.find((s) => s._id === currentFieldSection);
  return (
    <div>
      <b>{section?.name}</b>
    </div>
  );
};
