import { Button } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TitleBar } from '../components/adminLayout/TitleBar';
import { OfferForm } from '../components/offerbuilder/OfferForm';
import { AdminLayout } from '../layouts/AdminLayout';
export const OfferBuilder: React.FC = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <AdminLayout>
      <TitleBar
        title="Angebotsbaukasten"
        actions={
          <>
            {id ? (
              <Link target="_blank" to={`/offer/${id}`}>
                <Button variant="outlined">Angebot öffnen</Button>
              </Link>
            ) : (
              <></>
            )}
          </>
        }
      />
      <OfferForm offerId={id} />
    </AdminLayout>
  );
};
