import { ArrowDownward, ArrowUpward, Delete, Edit } from '@mui/icons-material';
import { Button, FormControlLabel, LinearProgress, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useOfferData } from '../../../hooks/useOfferData';
import { deletePosition, deleteSection, deleteTemplate, updatePosition, updateSection } from '../../../services';
import { OfferPosition, OfferTemplate } from '../../../shared/interfaces/offer.interface';
import { BooleanFormField } from '../../shared/offer/BooleanFormField';
import { CreatePlanModal } from './CreatePlanModal';
import { CreatePositionModal } from './CreatePositionModal';
import { CreateSectionModal } from './CreateSectionModal';
import { PricingCell } from './PricingCell';

export const PositionTable: React.FC = () => {
  const { fetch, isLoading, offerPositions, offerTemplates, offerSections, setOfferPositions, setOfferSections } = useOfferData();
  const [editTemplate, setEditTemplate] = useState<OfferTemplate>();
  const [editPosition, setEditPosition] = useState<OfferPosition>();
  const [editMode, setEditMode] = useState(false);

  const [updatingPositions, setUpdatingPositions] = useState<string[]>([]);

  async function delSection(sectionId: string) {
    try {
      await deleteSection(sectionId);
      fetch();
    } catch (err) {
      console.error(err);
    }
  }

  async function sortSection(sectionId: string, mode: 'up' | 'down') {
    const sections = offerSections.slice();
    const idx = sections.findIndex((e) => e._id === sectionId);
    const element = sections[idx];
    sections.splice(idx, 1);
    if (mode === 'up') {
      sections.splice(idx - 1, 0, element);
    } else {
      sections.splice(idx + 1, 0, element);
    }
    try {
      setOfferSections(sections);
      sections.map(async (section, index) => {
        await updateSection(section._id, { sortIndex: index });
      });
    } catch (err) {}
  }

  async function delPosition(posId: string) {
    try {
      await deletePosition(posId);
      setOfferPositions(offerPositions.filter((pos) => pos._id !== posId));
    } catch (err) {
      console.error(err);
    }
  }

  async function delTemplate(templateId: string) {
    try {
      await deleteTemplate(templateId);
      fetch();
    } catch (err) {
      console.error(err);
    }
  }

  async function togglePositionEditable(value: boolean, positionId: string) {
    if (value === undefined) return;
    setUpdatingPositions([...updatingPositions, positionId]);
    try {
      await updatePosition(positionId, { isEditable: value as boolean });
      setOfferPositions(
        offerPositions.map((pos) => {
          if (pos._id === positionId) {
            return {
              ...pos,
              isEditable: value,
            };
          }
          return pos;
        })
      );
    } catch (err) {
      fetch();
      console.error(err);
    }
    setUpdatingPositions(updatingPositions.filter((v) => v !== positionId));
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-end space-x-3">
        <FormControlLabel control={<Switch checked={editMode} onChange={() => setEditMode(!editMode)} />} label="Editmode" />
        <CreateSectionModal onClose={() => console.log('')} onCreate={(data) => fetch()} allSections={offerSections} />
        <CreatePositionModal
          editPosition={editPosition}
          sections={offerSections}
          onClose={() => console.log('')}
          onCreate={(data) => {
            setEditPosition(undefined);
            fetch();
          }}
        />
        <CreatePlanModal
          editTemplate={editTemplate}
          onClose={() => setEditTemplate(undefined)}
          offerPositions={offerPositions}
          offerSections={offerSections}
          onCreate={(data) => {
            fetch();
            setEditTemplate(undefined);
          }}
        />
      </div>

      {isLoading && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table aria-label="">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Editierbar</TableCell>
              <TableCell>Feldtyp</TableCell>
              <TableCell>Preistyp</TableCell>
              <TableCell>Preis</TableCell>

              {offerTemplates.map((template) => (
                <>
                  <TableCell align="center">
                    {template.name}{' '}
                    {editMode && (
                      <>
                        <Button onClick={() => delTemplate(template._id)}>
                          <Delete />
                        </Button>
                        <Button onClick={() => setEditTemplate(template)}>
                          <Edit />
                        </Button>
                      </>
                    )}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {offerSections.map((section, index) => (
              <>
                <TableRow key={section.name}>
                  <TableCell component="th" scope="row" colSpan={5 + offerTemplates.length}>
                    <b>{section.name}</b>
                    {editMode && (
                      <>
                        <Button onClick={() => delSection(section._id)}>
                          <Delete />
                        </Button>
                        <Button disabled={index === 0} onClick={() => sortSection(section._id, 'up')}>
                          <ArrowUpward />
                        </Button>
                        <Button disabled={index === offerSections.length - 1} onClick={() => sortSection(section._id, 'down')}>
                          <ArrowDownward />
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>

                {offerPositions
                  .filter((pos) => pos.sectionId === section._id)
                  .map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.label}{' '}
                        {editMode && (
                          <>
                            <Button onClick={() => delPosition(row._id)}>
                              <Delete />
                            </Button>
                            <Button onClick={() => setEditPosition(row)}>
                              <Edit />
                            </Button>
                          </>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Switch
                          disabled={!editMode || updatingPositions.includes(row._id)}
                          checked={row.isEditable}
                          onChange={(e) => togglePositionEditable(e.target.checked, row._id)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.type}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.pricingType}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <PricingCell position={row} templates={offerTemplates} />
                      </TableCell>
                      {offerTemplates.map((template) => {
                        const value = template.positions.find((pos) => pos.positionId === row._id);

                        if (row.type === 'boolean') {
                          return (
                            <TableCell align="center" component="th" scope="row">
                              <BooleanFormField name={row._id} label={row.label} defaultValue={value?.defaultChecked} withoutLabel disabled />
                            </TableCell>
                          );
                        }

                        if (row.type === 'text') {
                          return (
                            <TableCell align="center" component="th" scope="row">
                              {value?.defaultText}
                            </TableCell>
                          );
                        }

                        return (
                          <>
                            <TableCell align="center" component="th" scope="row">
                              {value?.defaultValue}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
