import { Alert, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { AuthWrapper } from '../layouts/AuthWrapper';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://vystem.io/">
        vystem.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export const LoginPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const navigate = useNavigate();

  const { login } = useAuth();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);

    try {
      const status = await login(data.get('email')?.toString(), data.get('password')?.toString());
      console.log(status);
      if (status) navigate('/dashboard');
    } catch (err) {
      console.error(err);
      setErrorMsg('Es ist ein Fehler aufgetreten.');
    }
    setIsLoading(false);
  };

  return (
    <AuthWrapper redirectToLogin={false} redirectToDashboard={true}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Typography component="h1" variant="h5">
            vystem Sales
          </Typography>
          <Alert className="mt-5" severity="info">
            Login mittels normalen vystem Zugangsdaten. Der Sales Zugang muss zuvor von einem Administrator freigeschaltet werden.
          </Alert>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField disabled={isLoading} margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
            <TextField
              disabled={isLoading}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button disabled={isLoading} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Login
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Snackbar open={!!errorMsg} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => setErrorMsg(undefined)}>
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
    </AuthWrapper>
  );
};
