import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

interface NumberFormFieldProps {
  control: any;
  name: string;
  min?: string;
  max?: string;
  label: string;
  disabled?: boolean;
}

export const NumberFormField: React.FC<NumberFormFieldProps> = (props) => {
  const { control, name, label, min, max, disabled } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={{ min, max }}
      render={({ field: { onChange, value, name } }) => (
        <TextField
          id={name}
          label={label}
          disabled={disabled}
          type="number"
          inputProps={{ step: '.01', ...(min !== undefined && max !== undefined ? { min, max } : {}) }}
          value={value}
          onChange={(e) => onChange(parseFloat(e.target.value))}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};
