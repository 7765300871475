import { Alert, Button } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { PopulatedOffer } from '../../shared/interfaces/offer.interface';
import { ClickAndSign } from './ClickAndSign';

const SignatureHolder = ({ title }: { title: string }) => (
  <div className="w-6/12">
    <div className="flex flex-col w-9/12 max-w-xs mt-16">
      <hr className="border-black" />
      {title}
    </div>
  </div>
);

interface OfferSummarySignatureProps {
  offer?: PopulatedOffer;
  onReload?: () => void;
}

export const OfferSummarySignature: React.FC<OfferSummarySignatureProps> = ({ offer, onReload }) => {
  const [isSignModal, setIsSignModal] = useState(false);

  if (!offer) return null;

  if (offer.status === 'accepted')
    return (
      <Alert severity="success" className="mt-16">
        {`Dieses Angebot wurde${
          offer.date_signing_accepted ? ` am ${format(new Date(offer.date_signing_accepted), 'dd.MM.yyyy HH:mm')}` : ''
        } erfolgreich unterzeichnet.`}
      </Alert>
    );

  if (offer.status === 'rejected')
    return (
      <Alert severity="error" className="mt-16">
        {`Dieses Angebot wurde abgelehnt.`}
      </Alert>
    );

  function onModalClose() {
    setIsSignModal(false);
    onReload?.();
  }

  return (
    <div className="flex flex-col mt-32">
      <span className="font-bold mb-4">Signatur</span>
      <div className="flex flex-wrap w-full mb-6">
        <SignatureHolder title="Signatur" />
        <SignatureHolder title="Datum" />
        <SignatureHolder title="Gedruckter Name" />
      </div>
      {offer.status === 'published' && (
        <div className="print:hidden w-full flex flex-col">
          <Button className="mt-6 vystem-button" variant="contained" onClick={() => setIsSignModal(true)}>
            Click&Sign
          </Button>
          <ClickAndSign offer={offer} open={isSignModal} onClose={onModalClose} />
        </div>
      )}
    </div>
  );
};
