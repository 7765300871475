import { useState } from 'react';
import { Offer, OfferPosition, OfferTemplate, PopulatedOffer, PricingType } from '../shared/interfaces/offer.interface';

export interface CalculatedPosition {
  title: string;
  description?: string;
  quantity?: number | null;
  totalPrice: number | null;
  pricingType: PricingType | null;
  comment: string | null;
  discount: number | null;
  singleUnitPrice: number | null;
  value?: string | boolean | number;
  type?: 'text' | 'boolean' | 'number';
  sectionId?: string;
  eventFactor?: number;
}

export type TotalPrice = { yearlyContractPrice: number; monthlyContractPrice: number; totalPrice: number; templatePrice: number };

export function useOfferCalculation(offerPositions: OfferPosition[], offerTemplates: OfferTemplate[], offer?: Partial<PopulatedOffer>, useLocalState = true) {
  const [calculatedPositions, setCalculatedPositions] = useState<CalculatedPosition[]>([]);

  function calculatePositions(values: Partial<PopulatedOffer | Offer>): CalculatedPosition[] {
    if (!values?.positions || values.positions.length === 0) {
      if (useLocalState) setCalculatedPositions([]);
      return [];
    }

    const d = values.positions.map((pos) => {
      const calculatedPrice = _calculatePricePerField(pos.positionId, pos.value as number, values, pos.eventFactor);
      const discountedPrice = _applyDiscountToPrice(pos.discount, calculatedPrice);
      const position = offerPositions.find((p) => p._id === pos.positionId);
      const label = position?.label;

      /**
       * TODO: Might consider renaming position.value
       * as it is moved to quantity anyways
       */
      return {
        title: label || '',
        totalPrice: discountedPrice,
        value: pos.value,
        type: position?.type,
        description: position?.description,
        pricingType: position?.pricingType || null,
        quantity: typeof pos.value === 'number' ? (pos.value as number) : null,
        comment: pos.comment,
        discount: pos.discount,
        singleUnitPrice: _calculateSingleUnitPrice(position, values['template']),
        sectionId: position?.sectionId,
        eventFactor: pos?.eventFactor,
      };
    });

    if (useLocalState) setCalculatedPositions(d);
    return d;
  }

  function _applyDiscountToPrice(discount: number | null, calculatedPrice: number | null): number | null {
    if (calculatedPrice === null) return null;

    const discountValue = discount ? calculatedPrice * (discount / 100) : 0;
    return calculatedPrice - discountValue;
  }

  function _calculatePricePerField(field: string, value: number, allValues: Partial<PopulatedOffer | Offer>, eventFactor = 1): number | null {
    const position = offerPositions.find((p) => p._id === field);
    if (!position || !position.pricingType) return null;
    if (!allValues) return null;

    const participantsPosition = offerPositions.find((o) => o.isParticipantCountField);
    const participants = participantsPosition ? allValues.positions?.find((pos) => pos.positionId === participantsPosition._id)?.value : null;

    switch (position.pricingType) {
      case 'fixed':
        return position.price || 0;
      case 'pricePerAttendee':
        if (!participants) return null;
        if (participants && position.price) {
          return value * position.price * (participants as number);
        }
        return 0;
      case 'variable':
        return value * (position.price || 0);
      case 'variableTemplate':
        if (allValues['template']) {
          const offerTemplatePrice = _getOfferTemplatePriceForPosition(position, allValues['template']);
          if (offerTemplatePrice) {
            if (!participants) return null;
            if (participants) {
              /**
               * Workaround for e.g. livestream
               * as we do not have type variableTemplatePrivePerAttendee yet
               */
              const multiplyValue = position.isParticipantCountField ? 1 : value;
              return (offerTemplatePrice * (participants as number) * multiplyValue) / eventFactor;
            }

            return 0;
          }
        }
        return null;
      case 'variableTemplateFixPrice':
        if (allValues['template']) {
          const offerTemplatePrice = _getOfferTemplatePriceForPosition(position, allValues['template']);
          if (offerTemplatePrice) {
            return offerTemplatePrice * (value || 1);
          }
        }
        return null;
      default:
        return 0;
    }
  }

  function _getTemplateIdFromTemplate(template?: string | OfferTemplate): string | null {
    if (!template) return null;
    return typeof template === 'string' ? template : template._id;
  }

  function _calculateSingleUnitPrice(position?: OfferPosition, template?: string | OfferTemplate): number | null {
    if (!position || !template) return null;

    if (position.pricingType !== 'variableTemplate' && position.pricingType !== 'variableTemplateFixPrice') return position.price || null;

    return _getOfferTemplatePriceForPosition(position, template);
  }

  function _getOfferTemplatePriceForPosition(position?: OfferPosition, template?: string | OfferTemplate): number | null {
    if (!position) return null;

    const templateId = _getTemplateIdFromTemplate(template);
    if (!templateId) return null;

    const offerTemplate = offerTemplates.find((t) => t._id === templateId);
    if (!offerTemplate) return null;
    return offerTemplate.positions.find((pos) => pos.positionId === position._id)?.templatePrice || 0;
  }

  function calculateTotalPrice(positions?: CalculatedPosition[], offerOverwrite?: Offer | PopulatedOffer): TotalPrice {
    const customPositions = offerOverwrite?.customPositions || offer?.customPositions || [];

    const pos = positions || calculatedPositions;

    const positionTotalValues = pos.map((v) => v.totalPrice).filter((v) => v && typeof v === 'number') as number[];
    const customPositionTotalValues = customPositions.map((p) => p.price * (p.isDiscount ? -1 : 1));

    const totalPrice = [...positionTotalValues, ...customPositionTotalValues].reduce((cv, pv) => cv + pv, 0);

    const templatePrice = positionTotalValues.reduce((cv, pv) => cv + pv, 0);

    return {
      yearlyContractPrice: totalPrice,
      monthlyContractPrice: totalPrice / 12 / 0.85,
      totalPrice,
      templatePrice,
    };
  }

  return {
    calculatedPositions,
    calculatePositions,
    calculateTotalPrice,
  };
}
