import { Check, DataUsage, PaymentOutlined } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SettingsIcon from '@mui/icons-material/Settings';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import './App.css';
import { materialTheme } from './components/theme/MaterialTheme';
import { IndexPage } from './pages';
import { AcceptedOffersPage } from './pages/acceptedoffers';
import { ControllingPage } from './pages/controlling';
import { DashboardPage } from './pages/dashboard';
import { HealthPage } from './pages/health';
import { LoginPage } from './pages/login';
import { NotFound } from './pages/notFound';
import { OfferPage } from './pages/offer';
import { OfferBuilder } from './pages/offerbuilder';
import { OffersPage } from './pages/offers';
import { SettingsPage } from './pages/settings';
import { PayoutRequests } from './pages/payoutrequests';

export interface Route {
  path: string;
  title: string;
  component: JSX.Element;
  icon?: any;
  showInMenu: boolean;
}

export const routes = [
  {
    path: '/',
    element: <IndexPage />,
    title: 'vystem',
    showInMenu: false,
  },
  {
    path: '/login',
    element: <LoginPage />,
    title: 'Login',
    showInMenu: false,
  },
  {
    path: '/dashboard',
    element: <DashboardPage />,
    title: 'Dashboard',
    showInMenu: true,
    icon: <DashboardIcon />,
  },
  {
    path: '/offers',
    element: <OffersPage />,
    title: 'Angebote',
    showInMenu: true,
    icon: <LocalOfferIcon />,
  },
  {
    path: '/acceptedoffers',
    element: <AcceptedOffersPage />,
    title: 'Akzeptierte Angebote',
    showInMenu: true, // check for user?
    icon: <Check />,
  },
  {
    path: '/payoutrequests',
    element: <PayoutRequests />,
    title: 'Auszahlungsanfragen',
    showInMenu: true,
    icon: <PaymentOutlined />,
  },
  {
    path: '/offerbuilder',
    element: <OfferBuilder />,
    title: 'Angebotserstellung',
    showInMenu: false,
  },
  {
    path: '/controlling',
    element: <ControllingPage />,
    title: 'Controlling',
    showInMenu: true,
    icon: <DataUsage />,
  },
  {
    path: '/offerbuilder/:id',
    element: <OfferBuilder />,
    title: 'Angebotserstellung',
    showInMenu: false,
  },
  {
    path: '/settings',
    element: <SettingsPage />,
    title: 'Einstellungen',
    showInMenu: false,
    icon: <SettingsIcon />,
  },
  {
    path: '/health',
    element: <HealthPage />,
    title: 'Health',
    showInMenu: false,
  },
  {
    path: '/offer/:id',
    element: <OfferPage />,
    title: 'Offer',
    showInMenu: false,
  },
];

function App() {
  return (
    <ToastProvider placement="bottom-center" autoDismiss={5000}>
      <ThemeProvider theme={materialTheme}>
        <Router>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ToastProvider>
  );
}

export default App;
