import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

const StyledOutlinedInput = withStyles(() => ({
  root: {
    width: '15em',
    background: 'rgb(243, 244, 246)',
    '& label.Mui-focused': {
      color: '#4896F4',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(224, 224, 224, 1)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(6, 182, 212)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(8, 145, 178)',
      },
    },
  },
}))(TextField);

interface OfferSearchProps {
  offerId?: string;
  onChange: (text: string) => void;
}

export const OfferSearch: React.FC<OfferSearchProps> = ({ offerId, onChange }) => {
  return (
    <StyledOutlinedInput
      id="outlined-adornment-password"
      className="search-input"
      InputProps={{
        endAdornment: <SearchIcon htmlColor="rgb(8, 145, 178)" />,
      }}
      onChange={(e) => onChange(e.target.value)}
      label="Alle Angebote durchsuchen"
    />
  );
};
