import { Cancel, CancelOutlined, Close, Edit, Save } from '@mui/icons-material';
import { TableRow, TableCell, Select, SelectChangeEvent, MenuItem, TextField } from '@mui/material';
import { format } from 'date-fns';
import { PayoutRequest } from '../../shared/interfaces/common.interface';
import { useState } from 'react';
import { updatePayoutRequest } from '../../services';

export const PayoutTableRow = ({ payoutRequest }: { payoutRequest: PayoutRequest }) => {
  const [editingStatus, setEditingStatus] = useState(false);
  const [editingFeedback, setEditingFeedback] = useState(false);
  const [status, setStatus] = useState(payoutRequest.status);
  const [feedback, setFeedback] = useState(payoutRequest.feedback);

  async function handleStatusChange(event: SelectChangeEvent) {
    try {
      await updatePayoutRequest(payoutRequest._id, { status: event.target.value });
      setStatus(event.target.value);
      setEditingStatus(false);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleFeedbackChange() {
    try {
      await updatePayoutRequest(payoutRequest._id, { feedback });
      setEditingFeedback(false);
    } catch (err) {
      console.error(err);
    }
  }

  function getStatusTranslation(value: string) {
    if (value === 'new') return 'Neu';
    else if (value === 'inprogress') return 'Wird bearbeitet';
    else if (value === 'denied') return 'Abgelehnt';
    else return 'Bestätigt';
  }

  return (
    <TableRow>
      <TableCell className="w-1/5">{format(new Date(payoutRequest.datetime_created), 'dd.MM.yyyy')}</TableCell>
      <TableCell className="w-1/5">{payoutRequest.event.name}</TableCell>
      <TableCell className="w-1/5">{payoutRequest.amount.toFixed(2)}</TableCell>
      <TableCell className="w-1/5">
        {editingStatus ? (
          <Select value={status} onChange={handleStatusChange}>
            <MenuItem value={'new'}>{getStatusTranslation('new')}</MenuItem>
            <MenuItem value={'inprogress'}>{getStatusTranslation('inprogress')}</MenuItem>
            <MenuItem value={'denied'}>{getStatusTranslation('denied')}</MenuItem>
            <MenuItem value={'confirmed'}>{getStatusTranslation('confirmed')}</MenuItem>
          </Select>
        ) : (
          <div className="group flex justify-between">
            <p>{getStatusTranslation(status)}</p>
            <Edit onClick={() => setEditingStatus(true)} className="cursor-pointer invisible group-hover:visible" />
          </div>
        )}
      </TableCell>
      <TableCell className="w-1/5">
        {editingFeedback ? (
          <div className="flex justify-between items-center">
            <TextField defaultValue={feedback} onChange={(e) => setFeedback(e.target.value)} />
            <div className="flex">
              <Save className="cursor-pointer" onClick={handleFeedbackChange} />
              <Close
                className="cursor-pointer"
                onClick={() => {
                  setFeedback(payoutRequest.feedback);
                  setEditingFeedback(false);
                }}
              />
            </div>
          </div>
        ) : (
          <div className="group flex justify-between">
            <p>{feedback}</p>
            <Edit onClick={() => setEditingFeedback(true)} className="cursor-pointer invisible group-hover:visible" />
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};
