import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

interface BooleanFormFieldProps {
  control?: any;
  name: string;
  label: string;
  defaultValue?: boolean;
  withoutLabel?: boolean;
  disabled?: boolean;
}

export const BooleanFormField: React.FC<BooleanFormFieldProps> = (props) => {
  const { control, name, label, withoutLabel = false, disabled, defaultValue } = props;

  const { control: selfControl } = useForm();

  const formControl = control || selfControl;
  return (
    <Controller
      control={formControl}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, name } }) => (
        <div>
          <FormControlLabel
            control={
              <>
                <Checkbox disabled={disabled} checked={value} onChange={(e) => onChange(e.target.checked)} />
              </>
            }
            label={withoutLabel ? <></> : label}
            labelPlacement="start"
          />
        </div>
      )}
    />
  );
};
