import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useOfferCalculation } from '../../hooks/useOfferCalculation';
import { useOfferData } from '../../hooks/useOfferData';
import { VystemStickyHeader } from '../common/VystemStickyHeader';
import { OfferSummaryComment } from './OfferSummaryComment';
import { OfferSummaryContact } from './OfferSummaryContact';
import { OfferSummaryExpired } from './OfferSummaryExpired';
import { OfferSummaryHeader } from './OfferSummaryHeader';
import { OfferSummarySignature } from './OfferSummarySignature';
import { OfferSummaryTable } from './OfferSummaryTable';
import { OfferSummaryTerms } from './OfferSummaryTerms';

export const OfferSummary: React.FC = () => {
  const { id } = useParams();

  const { fetchPublic, isLoading, offerPositions, offerTemplates, offerSections, deals, currentOffer, isOfferExpired } = useOfferData(id);

  const { calculatePositions, calculatedPositions, calculateTotalPrice } = useOfferCalculation(offerPositions, offerTemplates, currentOffer);

  useEffect(() => {
    document.title = 'vystem';
    fetchPublic();
  }, []);

  useEffect(() => {
    if (offerPositions && offerTemplates && currentOffer) calculatePositions(currentOffer);
    if (currentOffer) {
      document.title = `vystem Angebot: ${currentOffer.name}`;
    }
  }, [offerPositions, offerTemplates, currentOffer]);

  const componentRef = useRef(null);

  if (isLoading) return <CircularProgress />;

  if (currentOffer && isOfferExpired(currentOffer)) return <OfferSummaryExpired offer={currentOffer} />;

  if (!currentOffer) return <OfferSummaryExpired />;
  return (
    <div className="no-print-margin">
      <div className="flex flex-col w-full pb-8">
        <VystemStickyHeader />
        <div ref={componentRef} className="mt-16 no-print-margin print:m-5">
          <OfferSummaryHeader offer={currentOffer} />
          <div className="w-full flex justify-center">
            <div className="w-full flex flex-col" style={{ maxWidth: '210mm', overflow: 'visible' }}>
              <OfferSummaryComment offer={currentOffer} />

              <OfferSummaryTable
                offer={currentOffer}
                offerSections={offerSections}
                calculatedPositions={calculatedPositions}
                totalPrice={calculateTotalPrice()}
              />
              <OfferSummaryTerms offer={currentOffer} />
              <OfferSummarySignature offer={currentOffer} onReload={fetchPublic} />
              <OfferSummaryContact contact={currentOffer?.hubspotMetaData?.owner} showSalesManager={currentOffer?.showSalesManager} />
            </div>
          </div>
        </div>
        <div className="print:hidden mt-8 flex justify-center w-full">
          <div className="space-x-2 w-full" style={{ maxWidth: '210mm' }}>
            <Button onClick={() => window.print()} variant="outlined">
              Angebot drucken
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
