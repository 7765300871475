import LogoutIcon from '@mui/icons-material/Logout';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../App';
import { removeToken } from '../../helpers/auth';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
  },
}));

export const Sidebar: React.FC = (props) => {
  const navigate = useNavigate();

  const classes = useStyles();

  function logout() {
    removeToken();
    navigate('/login');
  }

  return (
    <Drawer classes={{ paper: classes.drawer }} variant="permanent" anchor="left">
      <List>
        {routes
          .filter((r) => r.showInMenu)
          .map((item) => (
            <Link key={item.title} to={item.path} style={{ textDecoration: 'none', color: 'black' }}>
              <ListItem button key={item.title}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            </Link>
          ))}
        <Divider />
        <ListItem button key={'logout'} onClick={logout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={'Logout'} />
        </ListItem>
      </List>
    </Drawer>
  );
};
