import axios from 'axios';

const ACCESS_TOKEN = 'access_token';
const ROLE = 'role';

export const setToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
  axios.defaults.headers.common.authorization = `Bearer ${token}`;
};

export const getToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setTokenFromLocalStorage = () => {
  const token = getToken();
  if (token) setToken(token);
};

/**
 * Workaround function due to 403 issue on frontend
 */
export const isTokenSet = () => {
  return !!getToken();
};

export const removeToken = () => {
  localStorage.clear();
  axios.defaults.headers.common.authorization = '';
};

export const logout = () => {
  removeToken();
};

export const login = (token: string, roleLevel: string) => {
  setToken(token);
  localStorage.setItem(ROLE, roleLevel);
};
