import { TextField } from '@mui/material';
import { OfferPosition } from '../../../shared/interfaces/offer.interface';
import { BooleanFormField } from './BooleanFormField';
import { NumberFormField } from './NumberFormField';

interface OfferFormFieldProps {
  positions: OfferPosition[];
  positionId: string;
  register: any;
  control: any;
  name: string;
  disabled?: boolean;
  index?: number;
}

export const OfferFormField: React.FC<OfferFormFieldProps> = (props) => {
  const { positions, positionId, register, control, name, disabled, index } = props;

  const matchingPosition = positions.find((pos) => pos._id === positionId);
  if (!matchingPosition) return <></>;

  return (
    <div className="flex flex-col space-y-2">
      {matchingPosition.type === 'boolean' && <BooleanFormField disabled={disabled} control={control} label={matchingPosition.label} name={name} />}
      {matchingPosition.type === 'number' && <NumberFormField disabled={disabled} control={control} label={matchingPosition.label} name={name} />}
      {matchingPosition.type === 'text' && (
        <TextField {...register(name)} disabled={disabled} id="outlined-basic" label={matchingPosition.label} variant="outlined" />
      )}
      {index && !matchingPosition.isParticipantCountField && matchingPosition.pricingType === 'variableTemplate' ? (
        <>
          <p>
            Wenn nicht alle Teilnehmer im Kontingent alle Livestreamingstunden schauen, kann hier die Anzahl der Events hinterlegt werden. Dabei wird dann
            gerechnet wie viele Zuschauer im Schnitt pro Event zuschauen.
          </p>
          <NumberFormField
            disabled={disabled}
            control={control}
            label={`(Optional) Eventfaktor: ${matchingPosition.label}`}
            name={`positions.${index}.eventFactor`}
          />
        </>
      ) : null}
    </div>
  );
};
