import { Avatar, LinearProgress, TableFooter, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOfferCalculation } from '../../hooks/useOfferCalculation';
import { useOfferData } from '../../hooks/useOfferData';
import { getAcceptedOffers, getAllOffers } from '../../services';
import { Offer } from '../../shared/interfaces/offer.interface';
import { EuroDisplay } from '../shared/offer/EuroDisplay';
import { OfferFilters } from './OfferFilters';
import { OfferSearch } from './OfferSearch';
import { OfferStatusCell } from './OfferStatus';
import './offerTable.css';
import { OfferTableActionButton } from './OfferTableActionButton';
import OfferTablePagination from './OfferTablePagination';

interface OfferTableProps {
  withSearchOption?: boolean;
  withFilterOption?: boolean;
  onlyAccepted?: boolean;
}

export const OfferTable: React.FC<OfferTableProps> = ({ withSearchOption = true, withFilterOption = true, onlyAccepted = false }) => {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [filteredOffers, setFilteredOffers] = useState<Offer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchText, setSearchText] = useState('');
  const [ownerFilter, setOwnerFilter] = useState('Alle Benutzer');
  const navigate = useNavigate();

  const { fetch, offerPositions, offerTemplates } = useOfferData();
  const { calculatePositions, calculateTotalPrice } = useOfferCalculation(offerPositions, offerTemplates, undefined, false);

  async function fetchAllOrderData() {
    setIsLoading(true);
    try {
      const oData = onlyAccepted ? await getAcceptedOffers() : await getAllOffers();
      await fetch();
      setOffers(oData);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchAllOrderData();
  }, []);

  useEffect(() => {
    if (!offers) return;
    let filteredOffers = [...offers];
    if (searchText) filteredOffers = filteredOffers.filter((offer) => offer.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
    if (ownerFilter && ownerFilter !== 'Alle Benutzer') filteredOffers = filteredOffers.filter((offer) => offer.hubspotMetaData?.owner?.name === ownerFilter);
    setFilteredOffers(filteredOffers);
  }, [offers, searchText, ownerFilter]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  //if (isLoading) return <CircularProgress />;

  const tableHeadeCellClassName = 'offer-table-cell bg-gray-100';

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex space-x-4">
        {withSearchOption && <OfferSearch onChange={(text) => setSearchText(text)} />}
        {withFilterOption && <OfferFilters offers={offers} currentOwner={ownerFilter} onChange={(owner) => setOwnerFilter(owner)} />}
      </div>
      {isLoading && <LinearProgress />}
      <TableContainer>
        <Table aria-label="" className="offer-table template-border">
          <TableHead>
            <TableRow>
              <TableCell className={tableHeadeCellClassName} align="left">
                ANGEBOT
              </TableCell>
              <TableCell className={tableHeadeCellClassName} align="left">
                ANGEBOTSBETRAG
              </TableCell>
              <TableCell className={tableHeadeCellClassName} align="left">
                STATUS
              </TableCell>
              <TableCell className={tableHeadeCellClassName} align="left">
                ZUSTÄNDIGER MITARBEITER FÜR ANGEBOT
              </TableCell>
              <TableCell className={tableHeadeCellClassName} align="left">
                ERSTELLUNGSDATUM
              </TableCell>
              <TableCell className={tableHeadeCellClassName} align="left">
                ABLAUFDATUM
              </TableCell>
              <TableCell className={tableHeadeCellClassName} align="left">
                ABSCHLUSSDATUM
              </TableCell>
              <TableCell className={tableHeadeCellClassName} align="left">
                ZULETZT AUFGERUFEN
              </TableCell>
              <TableCell className={tableHeadeCellClassName} align="left">
                DEAL-NAME
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 ? filteredOffers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredOffers).map((entry) => {
              const calculatedPositions = calculatePositions(entry);

              const calculatedPrice = calculateTotalPrice(calculatedPositions, entry);

              return (
                <TableRow key={entry._id} className="offer-row hover:bg-gray-100">
                  <TableCell align="left" component="th" scope="row">
                    <div className="flex justify-between relative items-center no-wrap max-w-10 offer-name-container">
                      <Tooltip title={entry.name}>
                        <div onClick={() => navigate(`/offerbuilder/${entry._id}`)} className="truncate font-bold cursor-pointer">
                          {entry.name}{' '}
                        </div>
                      </Tooltip>
                      <OfferTableActionButton offerId={entry._id} onFinish={fetchAllOrderData} />
                    </div>
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <EuroDisplay value={calculatedPrice.totalPrice} />
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <OfferStatusCell
                      offer={entry}
                      onStatusChange={(status) => {
                        setOffers(
                          offers.map((o) => {
                            if (o._id === entry._id) {
                              return {
                                ...o,
                                status,
                              };
                            }
                            return o;
                          })
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <div className="flex justify-center items-center">
                      <Avatar style={{ width: '30px', height: '30px' }} src={`https://static.vystem.io/${entry?.hubspotMetaData?.owner?.vystemUser?.avatar}`} />
                      <span className="flex-1 ml-2">{entry?.hubspotMetaData?.owner?.name}</span>
                    </div>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    {entry.datetime_created && format(new Date(entry.datetime_created), 'dd.MM.yyyy HH:mm')}
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    {entry.datetime_expiry && format(new Date(entry.datetime_expiry), 'dd.MM.yyyy')}
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    {entry.date_signing_accepted && format(new Date(entry.date_signing_accepted), 'dd.MM.yyyy')}
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    {entry.datetime_last_open && format(new Date(entry.datetime_last_open), 'dd.MM.yyyy HH:mm')}
                  </TableCell>
                  <TableCell className="font-bold" align="left" component="th" scope="row">
                    {entry.hubspotDealId && entry.hubspotMetaData?.companies[0]?.name && (
                      <Tooltip title={entry.hubspotMetaData?.companies[0]?.name || ''}>
                        <div
                          className="truncate font-bold offer-deal-container cursor-pointer"
                          onClick={() => window.open(`https://app.hubspot.com/contacts/7712979/deal/${entry.hubspotDealId}`, '_blank')}>
                          {entry.hubspotMetaData?.companies[0]?.name}
                        </div>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <OfferTablePagination
                count={filteredOffers.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={(value) => setRowsPerPage(value)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
