import http from './helpers/http';
import { ControllingEntry } from './pages/controlling';
import { PartialUser } from './shared/interfaces/common.interface';
import { DealAssociationMetaData, HSDeals, Offer, OfferPosition, OfferSection, OfferTemplate, PopulatedOffer } from './shared/interfaces/offer.interface';

interface VystemResponse<T> {
  status: 'ok' | 'error';
  data: T;
}

interface LoginReturnData {
  access_token: string;
  role: any;
  user: any;
}

export async function loginService(email?: string, password?: string): Promise<LoginReturnData> {
  const { data: response } = await http.post('auth/login', {
    email,
    password,
    scope: 'sales',
  });
  return response.data;
}

interface GetAuthData {
  user: PartialUser;
  role: {
    name: string;
    level: number;
  };
}
export async function getAuth(): Promise<GetAuthData> {
  const { data: response } = await http.post('auth/get');
  return response.data;
}

export async function getUsersByEmail(email: string): Promise<PartialUser[]> {
  const { data: response } = await http.post('user/getall', {
    limit: 1000,
    filter: email,
  });
  return response.data;
}

export async function getAllUsersWithSalesAccess(): Promise<PartialUser[]> {
  const { data: response } = await http.post('user/getall', {
    query: { hasSalesAccess: true },
  });
  return response.data;
}

export const getSetupData = async (): Promise<{ sections: OfferSection[]; positions: OfferPosition[]; templates: OfferTemplate[] }> => {
  const { data: data } = await http.get(`/sales/offer/setup`);
  return data.data;
};

export const getPendingDeals = async (): Promise<HSDeals[]> => {
  const { data: data } = await http.get(`/sales/hs/deals/getall`);
  return data.data;
};

export const getDealData = async (dealId: string): Promise<DealAssociationMetaData> => {
  const { data: data } = await http.get(`/sales/hs/deal/${dealId}`);
  return data.data;
};

export type callsPerUserRecord = { username: string; userid: string; calls: { withLength: number; withNoLength: number } };
export const getCallsPerUser = async (timeframe?: { from: Date; to: Date }): Promise<callsPerUserRecord[]> => {
  const { data: data } = await http.post(`/sales/hs/calls/query`, {
    datetime_start: timeframe?.from,
    datetime_end: timeframe?.to,
  });
  return data.data;
};

export const getAllOffers = async (): Promise<Offer[]> => {
  const { data: data } = await http.get(`/sales/offer`);
  return data.data;
};

export const getAcceptedOffers = async (): Promise<Offer[]> => {
  const { data: data } = await http.get(`/sales/offer/accepted`);
  return data.data;
};

export const getOffer = async (id: string): Promise<PopulatedOffer> => {
  const { data: data } = await http.get(`/sales/offer/${id}`);
  return data.data;
};

export const trackOpen = async (id: string): Promise<PopulatedOffer> => {
  const { data: data } = await http.get(`/sales/offer/open/${id}`);
  return data.data;
};

export const createOffer = async (offerPayload: Partial<Offer>): Promise<Offer> => {
  const { data: data } = await http.post(`/sales/offer`, offerPayload);
  return data.data;
};

export const updateOffer = async (id: string, offerPayload: Partial<Offer>): Promise<Offer> => {
  const { data: data } = await http.patch(`/sales/offer/${id}`, offerPayload);
  return data.data;
};

export const deleteOffer = async (id: string): Promise<VystemResponse<undefined>> => {
  const { data: data } = await http.delete(`/sales/offer/${id}`);
  return data;
};

export const duplicateOffer = async (id: string): Promise<VystemResponse<undefined>> => {
  const { data: data } = await http.post(`/sales/offer/${id}/duplicate`);
  return data;
};

export const createSection = async (sectionName: string, sortIndex?: number): Promise<OfferSection> => {
  const { data: data } = await http.post(`/sales/offer/section`, {
    name: sectionName,
    sortIndex,
  });
  return data.data;
};

export const updateSection = async (sectionId: string, updateObject: Partial<OfferSection>): Promise<VystemResponse<undefined>> => {
  const { data: data } = await http.patch(`/sales/offer/section/${sectionId}`, {
    ...updateObject,
  });
  return data;
};

export const deleteSection = async (id: string): Promise<VystemResponse<undefined>> => {
  const { data: data } = await http.delete(`/sales/offer/section/${id}`);
  return data;
};

export const createPosition = async (o: OfferPosition): Promise<VystemResponse<undefined>> => {
  const { data: data } = await http.post(`/sales/offer/position`, { ...o });
  return data;
};

export const updatePosition = async (id: string, o: Partial<OfferPosition>): Promise<VystemResponse<undefined>> => {
  const { data: data } = await http.patch(`/sales/offer/position/${id}`, { ...o });
  return data;
};

export const deletePosition = async (id: string): Promise<VystemResponse<undefined>> => {
  const { data: data } = await http.delete(`/sales/offer/position/${id}`);
  return data;
};

export const createTemplate = async (o: OfferTemplate): Promise<VystemResponse<undefined>> => {
  const { data: data } = await http.post(`/sales/offer/template`, { ...o });
  return data;
};

export const updateTemplate = async (id: string, o: OfferTemplate): Promise<VystemResponse<undefined>> => {
  const { data: data } = await http.patch(`/sales/offer/template/${id}`, {
    ...o,
  });
  return data;
};

export const deleteTemplate = async (id: string): Promise<VystemResponse<undefined>> => {
  const { data: data } = await http.delete(`/sales/offer/template/${id}`);
  return data;
};

interface UserCreateProps {
  name: string;
  email: string;
  role: string;
  hasSalesAccess?: boolean;
}

export const createUser = async (props: UserCreateProps): Promise<VystemResponse<undefined>> => {
  const { data } = await http.post('/user/create', props);
  return data;
};

interface UserUpdateProps {
  userid: string;
  hasSalesAccess: boolean;
}
export const updateUserSalesAccess = async (props: UserUpdateProps): Promise<VystemResponse<undefined>> => {
  const { data } = await http.post('/user/update', props);
  return data;
};

export const getAllOrgs = async (): Promise<VystemResponse<{ name: string; _id: string }[]>> => {
  const { data: data } = await http.get(`/organization/all`);
  return data;
};

interface RequestSignProps {
  offerId: string;
  phonenumber: string;
}
export const requestSign = async (props: RequestSignProps): Promise<VystemResponse<undefined>> => {
  return await http.post(`/sales/sign/request`, props);
};

interface SignProps {
  offerId: string;
  otp: number;
}
export const signOffer = async (props: SignProps): Promise<VystemResponse<undefined>> => {
  return await http.post(`/sales/sign/sign`, props);
};

export const getControllingSummary = async (): Promise<VystemResponse<ControllingEntry[]>> => {
  const { data: data } = await http.get(`/controlling`);
  return data;
};

export const getPayoutRequests = async () => {
  const { data: data } = await http.get('/sales/payoutrequest');
  return data;
};

export const updatePayoutRequest = async (_id: string, body: any) => {
  const { data: data } = await http.put(`/sales/payoutrequest/${_id}`, { ...body });
  return data;
};
