import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TitleBar } from '../components/adminLayout/TitleBar';
import { OfferTable } from '../components/offers/OfferTable';
import { AdminLayout } from '../layouts/AdminLayout';
import { PayoutTable } from '../components/payout/PayoutTable';

export const PayoutRequests: React.FC = () => {
  return (
    <AdminLayout>
      <TitleBar title="Auszahlungsanfragen" />
      <PayoutTable />
    </AdminLayout>
  );
};
