import { endOfDay, isAfter } from 'date-fns';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { getAllOrgs, getOffer, getPendingDeals, getSetupData, trackOpen } from '../services';
import { HSDeals, Offer, OfferPosition, OfferSection, OfferTemplate, PopulatedOffer } from '../shared/interfaces/offer.interface';

export function useOfferData(offerId?: string) {
  const { addToast } = useToasts();

  const [selectedOfferId, setSelectedOfferId] = useState(offerId);
  const [isLoading, setIsLoading] = useState(false);
  const [offerPositions, setOfferPositions] = useState<OfferPosition[]>([]);
  const [offerTemplates, setOfferTemplates] = useState<OfferTemplate[]>([]);
  const [offerSections, setOfferSections] = useState<OfferSection[]>([]);
  const [currentOffer, setCurrentOffer] = useState<PopulatedOffer>();

  const [deals, setDeals] = useState<HSDeals[]>([]);

  const [organizations, setOrganizations] = useState<{ name: string; _id: string }[]>([]);

  async function fetchPublic(oId?: string) {
    setIsLoading(true);
    try {
      if (selectedOfferId || oId) {
        const selectedOffer = await getOffer((oId || selectedOfferId) as string);
        await trackOpen((oId || selectedOfferId) as string).catch((e) => {}); // to avoid throwing errors we do an empty catch
        setCurrentOffer(selectedOffer);
      }

      const data = await getSetupData();
      setOfferSections(data.sections);
      setOfferPositions(data.positions);
      setOfferTemplates(data.templates);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  async function fetch(oId?: string, showErrorToast = true) {
    if (oId) setSelectedOfferId(oId);
    setIsLoading(true);
    try {
      if (selectedOfferId || oId) {
        const selectedOffer = await getOffer((oId || selectedOfferId) as string);
        setCurrentOffer(selectedOffer);
      }
      const [data, deals, allOrgs] = await Promise.all([getSetupData(), getPendingDeals(), getAllOrgs()]);
      setOrganizations(allOrgs.data);
      setOfferSections(data.sections);
      setOfferPositions(data.positions);
      setOfferTemplates(data.templates);
      setDeals(deals);
    } catch (err) {
      if (showErrorToast) addToast('Die Daten konnten nicht richtig geladen werden. Bitte versuchen Sie es erneut.', { appearance: 'warning' });
      console.error(err);
    }
    setIsLoading(false);
  }

  function isOfferExpired(offer?: PopulatedOffer | Offer): boolean {
    if (!offer || !offer.datetime_expiry) return false;

    // an offer is expired if it is not accepted or declined AND the expiry date has already passed
    return isAfter(new Date(), endOfDay(new Date(offer?.datetime_expiry))) && offer.status === 'published';
  }

  return {
    isLoading,
    offerPositions,
    offerTemplates,
    offerSections,
    currentOffer,
    deals,
    organizations,
    fetch,
    setOfferPositions,
    setOfferSections,
    isOfferExpired,
    fetchPublic,
  };
}
