import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';

interface TextEditorProps {
  control: any;
  name: string;
  label: string;
  height?: number;
  disabled?: boolean;
}

export const TextEditor: React.FC<TextEditorProps> = (props) => {
  const { control, name, height = 300, label, disabled = false } = props;

  const editorRef = useRef<any>(null);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => {
        function checkUpdatable() {
          const content = editorRef.current.getContent();
          if (content !== value) {
            onChange(editorRef.current.getContent());
          }
        }

        return (
          <>
            <p>{label}</p>
            <Editor
              disabled={disabled}
              apiKey="t67zitrwa4ddvpeyjnfwa2cixnen19gri46rgd3ttat13sr8"
              onInit={(evt, editor) => {
                editorRef.current = editor;
              }}
              initialValue={value}
              init={{
                height: height,
                menubar: false,
                branding: false,
                relative_urls: false,
                remove_script_host: false,
                browser_spellcheck: true,
                plugins: [
                  'advlist autolink lists image charmap print preview anchor',
                  'searchreplace visualblocks fullscreen',
                  'insertdatetime media table paste help wordcount',
                  'link',
                  'code',
                ],
                toolbar1: `code | undo redo | formatselect | fontsizeselect `,
                toolbar2: 'bold italic underline | forecolor | backcolor | alignleft aligncenter alignright alignjustify ',
                toolbar3: ' bullist numlist outdent indent | link | removeformat | help ',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                language_url: 'https://storage.googleapis.com/digital-event-production-master/de.js',
                language: 'de',
              }}
              onBlur={checkUpdatable}
            />
          </>
        );
      }}
    />
  );
};
