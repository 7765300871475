import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Alert, Autocomplete, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Switch, TextField, Tooltip } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useOfferCalculation } from '../../hooks/useOfferCalculation';
import { useOfferData } from '../../hooks/useOfferData';
import { createOffer, getDealData, updateOffer } from '../../services';
import { ContactMetaData, Offer, OfferStatus, PopulatedOffer } from '../../shared/interfaces/offer.interface';
import { VyConfirm } from '../common/VyConfirm';
import { BooleanFormField } from '../shared/offer/BooleanFormField';
import { NumberFormField } from '../shared/offer/NumberFormField';
import { OfferFormField } from '../shared/offer/OfferFormField';
import { OfferPreview } from './OfferPreview';
import { TextEditor } from './TextEditor';

const DEFAULT_VIDEOPRICE = 0.00395;
const DEFAULT_STREAMINGPRICE = 0.0012;
const DEFAULT_ENCODINGPRICE = 0.04;

export interface OfferFormProps {
  offerId?: string;
}

export const OfferForm: React.FC<OfferFormProps> = (props) => {
  const { offerId } = props;

  const navigate = useNavigate();
  const { addToast } = useToasts();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const { fields } = useFieldArray({
    control,
    name: 'positions',
  });

  const { fields: customPositionFields, append, remove, swap } = useFieldArray({
    control,
    name: 'customPositions',
  });

  const watchTemplate = watch('templateId');
  const watchStartDate = watch('datetime_created');
  const watchDealId = watch('hubspotDealId');
  const watchAll: any = watch();

  const [lastSelectedTemplate, setLastSelectedTemplate] = useState<string>();
  const [dealContacts, setDealContacts] = useState<ContactMetaData[]>([]);

  const { fetch, isLoading, offerPositions, offerTemplates, offerSections, deals, currentOffer, organizations } = useOfferData(offerId);
  const { calculatePositions, calculateTotalPrice } = useOfferCalculation(offerPositions, offerTemplates);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (Object.entries(errors).length > 0) {
      console.error(`Errors >`, errors);
      addToast('Ein Formfeld ist noch nicht korrekt', { appearance: 'warning' });
    }
  }, [errors]);

  useEffect(() => {
    if (!watchStartDate) return;
    const result = new Date(watchStartDate);
    result.setDate(result.getDate() + 14);
    setValue('datetime_expiry', result);
  }, [watchStartDate]);

  useEffect(() => {
    if (!watchDealId) return;
    fetchDealData();
  }, [watchDealId]);

  const [hiddenSectionIds, setHiddenSectionIds] = useState<string[]>([]);

  async function fetchDealData() {
    const dealData = await getDealData(watchDealId);
    setDealContacts(dealData.contacts);
  }

  function resetForm(resetObject?: Partial<PopulatedOffer>) {
    const rsObject = {
      ...resetObject,
      templateId: resetObject?.template?._id,
      datetime_license_start: resetObject?.datetime_license_start ?? null,
      positions: offerPositions.map((o) => {
        const valueMatchFromResetObject = resetObject ? resetObject.positions?.find((pos) => pos.positionId === o._id) : undefined;
        return {
          positionId: o._id,
          sectionId: o.sectionId,
          value: valueMatchFromResetObject?.value,
          comment: valueMatchFromResetObject?.comment,
          discount: valueMatchFromResetObject?.discount,
          eventFactor: valueMatchFromResetObject?.eventFactor || 1,
          isEditable: o.isEditable,
        };
      }),
    };

    setHiddenSectionIds(resetObject?.hiddenSections || []);
    reset(rsObject);
  }

  useEffect(() => {
    resetForm(currentOffer);
  }, [offerPositions, currentOffer]);

  useEffect(() => {
    const match = offerTemplates.find((template) => template._id === watchTemplate);
    if (!lastSelectedTemplate && offerId) {
      setLastSelectedTemplate(watchTemplate as string);
      return;
    }
    if (lastSelectedTemplate === watchTemplate) {
      return;
    }

    setLastSelectedTemplate(watchTemplate as string);

    let resetObject: any = {
      ...watchAll,
      template: match,
      positions: [],
    };

    if (match) {
      match.positions.map((position) => {
        const oPosition = offerPositions.find((pos) => pos._id === position.positionId);
        if (oPosition) {
          let value: any;
          switch (oPosition.type) {
            case 'text':
              value = position.defaultText;
              break;
            case 'boolean':
              value = position.defaultChecked;
              break;
            case 'number':
              value = position.defaultValue;
              break;
            default:
              break;
          }
          resetObject.positions.push({ positionId: oPosition._id, value });
        }
      });
    }
    resetForm(resetObject);
  }, [watchTemplate]);

  async function submit(props: Partial<Offer & { templateId: string }>, publish = false) {
    console.log(props);

    try {
      const calculatedPositions = calculatePositions(props);
      const totalPrice = calculateTotalPrice(calculatedPositions, props as Offer);

      const offerData = {
        ...props,
        template: props.templateId,
        hiddenSections: hiddenSectionIds,
        lastCalculatedTotalPrice: totalPrice.totalPrice,
        ...(publish ? { status: 'published' as OfferStatus } : {}),
      };

      if (currentOffer && currentOffer._id) {
        await updateOffer(currentOffer._id, offerData);
        addToast('Änderungen gespeichert', { appearance: 'success' });
        fetch();
      } else {
        const data = await createOffer(offerData);
        navigate(`/offerbuilder/${data._id}`, { replace: true });
        fetch(data._id);
      }
    } catch (err) {
      addToast('Es ist ein Fehler aufgetreten', { appearance: 'error' });
      console.error(err);
    }
  }

  if (isLoading || isSubmitting) return <CircularProgress />;

  const fieldsDisabled = currentOffer && currentOffer?.status !== 'draft';
  const orgsList = organizations.map((org) => ({ label: org.name, value: org._id }));

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <div className="flex flex-row w-full space-x-3">
        <form className="flex flex-col space-y-4 w-full pb-9" onSubmit={handleSubmit((d) => submit(d))}>
          {currentOffer?.status === 'published' && <Alert severity="success">Das Angebot wurde bereits veröffentlicht und kann nicht bearbeitet werden.</Alert>}
          <div>Stammdaten</div>
          <TextField disabled={fieldsDisabled} {...register('name', { required: true })} id="outlined-basic" label="Angebotsname" variant="outlined" />
          <TextEditor disabled={fieldsDisabled} control={control} name="description" label="Beschreibung / Kommentar" />
          <TextField disabled {...register('offerReferenceNumber')} id="outlined-basic" label="Angebotsnummer (automatisch)" variant="outlined" />
          <Controller
            control={control}
            name="organizationId"
            render={({ field: { onChange, value, name } }) => {
              const v = orgsList.find((d) => d.value === value);

              return (
                <Autocomplete
                  disablePortal
                  disabled={fieldsDisabled}
                  id="org-select"
                  onChange={(_, value) => {
                    onChange(value?.value);
                  }}
                  renderOption={(props, option: any) => (
                    <li {...props} key={option.value}>
                      {option.label}
                      <small className="ml-2">(OrgId: {option.value})</small>
                    </li>
                  )}
                  value={v}
                  options={orgsList}
                  renderInput={(params: any) => <TextField {...params} label="vystem Org. Auswahl" />}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="hubspotDealId"
            render={({ field: { onChange, value, name } }) => {
              const dealList = deals.map((el) => {
                return {
                  value: el.id,
                  label: `${el.dealname} [${el.dealstageName}]`,
                };
              });

              const v = dealList.find((d) => d.value === value);

              return (
                <Autocomplete
                  disablePortal
                  id="deal-select"
                  disabled={fieldsDisabled}
                  onChange={(_, value) => {
                    onChange(value?.value);
                  }}
                  value={v}
                  options={dealList}
                  renderInput={(params: any) => <TextField {...params} label="Hubspot Deal Auswahl" />}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="selectedContactIds"
            render={({ field: { onChange, value } }) => {
              const contactsList = dealContacts.map((el) => {
                return {
                  value: el.id,
                  label: `${el.firstname} ${el.lastname}`,
                };
              });

              const currentValue = contactsList
                .map((c) => {
                  if (value?.includes(c.value)) return c;
                })
                .filter((c) => c);

              return (
                <Autocomplete
                  multiple
                  id="deal-contacts-select"
                  disabled={fieldsDisabled || !watchDealId}
                  options={contactsList}
                  value={currentValue}
                  onChange={(_, value) => {
                    if (value) onChange(value.map((v) => v?.value));
                  }}
                  renderInput={(params: any) => <TextField {...params} label="Kontakte" />}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="datetime_created"
            render={({ field: { onChange, value } }) => (
              <DesktopDatePicker
                label="Angebotsdatum"
                inputFormat="DD.MM.YYYY"
                disabled={fieldsDisabled}
                value={moment(value)}
                onChange={onChange}
                renderInput={(params: any) => <TextField {...params} />}
              />
            )}
          />
          <Controller
            control={control}
            name="datetime_expiry"
            rules={{ required: true }}
            defaultValue={new Date().setDate(new Date().getDate() + 14)}
            render={({ field: { onChange, value } }) => (
              <DesktopDatePicker
                label="Verfallsdatum"
                inputFormat="DD.MM.YYYY"
                minDate={watchStartDate ? moment(new Date(watchStartDate)) : undefined}
                value={value ? moment(new Date(value)) : undefined}
                onChange={onChange}
                renderInput={(params: any) => <TextField {...params} />}
              />
            )}
          />
          {/*<Controller
            control={control}
            name="datetime_license_start"
            rules={{ required: false }}
            defaultValue={null}
            render={({ field: { onChange, value } }) => (
              <DesktopDatePicker
                label="Lizenzstartdatum"
                inputFormat="DD.MM.YYYY"
                value={value ? moment(new Date(value)) : null}
                onChange={onChange}
                renderInput={(params: any) => <TextField {...params} />}
              />
            )}
          />
          <TextField
            type="number"
            disabled={fieldsDisabled}
            {...register('licenseLengthInMonths', { required: true, valueAsNumber: true })}
            id="outlined-basic"
            label="Lizenzlänge (Monate)"
            variant="outlined"
          />*/}

          <Divider />
          {/* offerTemplates.length > 0 ? (
            <Controller
              control={control}
              name="templateId"
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <InputLabel id="template-select-label">Template</InputLabel>
                  <Select
                    disabled={fieldsDisabled}
                    value={!value ? 'no-template' : value}
                    onChange={(e) => onChange(e.target.value !== 'no-template' ? e.target.value : undefined)}
                    labelId="template-select-label"
                    id="template-select"
                    label="Template">
                    <MenuItem key={`no-template`} value="no-template">
                      Kein Template
                    </MenuItem>
                    {offerTemplates.map((template) => (
                      <MenuItem key={template._id} value={template._id}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          ) : (
            <Alert severity="warning">Keine Templates angelegt.</Alert>
          )*/}
          <Controller
            control={control}
            name="datetime_license_start"
            defaultValue={currentOffer?.datetime_license_start}
            render={({ field: { onChange, value } }) => (
              <DesktopDatePicker
                label="Lizenzstart (optional)"
                inputFormat="DD.MM.YYYY"
                disabled={fieldsDisabled}
                minDate={watchStartDate ? moment(new Date(watchStartDate)) : undefined}
                value={!value || value === null ? null : moment(new Date(value))}
                onChange={onChange}
                renderInput={(params: any) => <TextField {...params} />}
              />
            )}
          />
          <TextField
            disabled={fieldsDisabled}
            {...register('licenseLengthInMonths', { required: true })}
            type="number"
            label="Lizenzlaufzeit (in Monaten)"
            id="outlined-basic"
            variant="outlined"
          />
          <Divider />
          <TextField
            disabled={fieldsDisabled}
            {...register('videoPrice')}
            label="Preis pro Videominute in EUR"
            variant="outlined"
            defaultValue={currentOffer?.videoPrice ?? DEFAULT_VIDEOPRICE}
          />
          <TextField
            disabled={fieldsDisabled}
            {...register('streamingPrice')}
            label="Preis pro Streaming-Minute in EUR"
            variant="outlined"
            defaultValue={currentOffer?.streamingPrice ?? DEFAULT_STREAMINGPRICE}
          />
          <TextField
            disabled={fieldsDisabled}
            {...register('encodingPrice')}
            label="Preis pro Encoding-Minute in EUR"
            variant="outlined"
            defaultValue={currentOffer?.encodingPrice ?? DEFAULT_ENCODINGPRICE}
          />
          <Divider />
          <div>
            <span>Preise pro Artikel anzeigen</span>
            <Controller
              control={control}
              name="showPricesPerPosition"
              defaultValue={currentOffer?.showPricesPerPosition}
              render={({ field: { onChange, value } }) => <Switch checked={value} disabled={fieldsDisabled} onChange={(e) => onChange(e.target.checked)} />}
            />
          </div>
          <div>
            <span>Saleskontakt anzeigen</span>
            <Controller
              control={control}
              name="showSalesManager"
              defaultValue={currentOffer?.showSalesManager}
              render={({ field: { onChange, value } }) => <Switch checked={value} disabled={fieldsDisabled} onChange={(e) => onChange(e.target.checked)} />}
            />
          </div>
          <div>
            <span>Monatliche Zahlung anzeigen</span>
            <Controller
              control={control}
              name="showMonthlyPayment"
              defaultValue={currentOffer?.showMonthlyPayment}
              render={({ field: { onChange, value } }) => <Switch checked={value} disabled={fieldsDisabled} onChange={(e) => onChange(e.target.checked)} />}
            />
          </div>
          <TextEditor disabled={!watchTemplate || fieldsDisabled} label="Template Zusatz" control={control} name="customTemplateText" height={200} />

          {watchTemplate &&
            offerSections.map((section) => {
              const matchingPositions = fields.filter((f: any) => f.sectionId === section._id);

              return (
                <div>
                  <div>
                    <b>{section?.name}</b>
                    {watchAll && (
                      <Tooltip title="Sektion im Angebot anzeigen" placement="right">
                        <Switch
                          disabled={fieldsDisabled}
                          checked={!hiddenSectionIds?.includes(section._id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setHiddenSectionIds(hiddenSectionIds?.filter((v: any) => v !== section._id) || []);
                            } else {
                              setHiddenSectionIds([...hiddenSectionIds, section._id]);
                            }
                          }}
                        />
                      </Tooltip>
                    )}{' '}
                  </div>

                  <div className="flex flex-col space-y-2">
                    {matchingPositions.map((pos: any) => {
                      const idx = fields.findIndex((f: any) => f.id === pos.id);

                      if (!pos.isEditable) return <input type="hidden" {...register(`positions.${idx}.value`)} />;

                      return (
                        <div key={pos.id}>
                          <OfferFormField
                            disabled={fieldsDisabled}
                            name={`positions.${idx}.value`}
                            control={control}
                            positions={offerPositions}
                            positionId={pos.positionId}
                            register={register}
                            index={idx}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          <Divider />
          <div className="flex flex-col space-y-3">
            <b>Zusatzpositionen</b>
            {customPositionFields.map((f, index, allFields) => (
              <div key={f.id} className="flex flex-col space-y-2 bg-gray-200 p-3">
                <TextEditor disabled={fieldsDisabled} label="Beschreibung" control={control} name={`customPositions.${index}.positionText`} height={200} />
                <NumberFormField disabled={fieldsDisabled} control={control} label="Preis" name={`customPositions.${index}.price`} min="0" max="1000000" />
                <BooleanFormField disabled={fieldsDisabled} control={control} label="Ist Abzug / Discount" name={`customPositions.${index}.isDiscount`} />
                <div className="flex flex-row w-full">
                  <Button disabled={fieldsDisabled} onClick={() => remove(index)}>
                    Position löschen
                  </Button>
                  <Button disabled={fieldsDisabled || index === 0} onClick={() => swap(index, index - 1)}>
                    <ArrowUpward />
                  </Button>
                  <Button disabled={fieldsDisabled || index === allFields.length - 1} onClick={() => swap(index, index + 1)}>
                    <ArrowDownward />
                  </Button>
                </div>
              </div>
            ))}
            <Button disabled={fieldsDisabled} onClick={() => append({ positionText: '', price: 0 })}>
              + Zusatzposition hinzufügen
            </Button>
          </div>

          <Button type="submit" variant="contained">
            {currentOffer ? 'Änderungen speichern' : 'Speichern'}
          </Button>
          {currentOffer?._id && currentOffer.status !== 'published' && (
            <VyConfirm
              onConfirm={() => handleSubmit((d) => submit(d, true))()}
              className="w-full"
              title="Soll das Angebot wirklich veröffentlicht werden?"
              message="Es können keine Änderungen mehr vorgenommen werden. Für Änderungen muss das Angebot dupliziert und angepasst werden.">
              <Button variant="contained" className="w-full">
                Angebot veröffentlichen
              </Button>
            </VyConfirm>
          )}
        </form>
        <div className="w-6/12">
          <OfferPreview
            values={{ ...watchAll, hiddenSections: hiddenSectionIds }}
            offerPositions={offerPositions}
            offerTemplates={offerTemplates}
            offerSections={offerSections}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};
