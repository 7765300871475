import { Button, Dialog, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createSection } from '../../../services';
import { OfferSection } from '../../../shared/interfaces/offer.interface';

interface CreateSectionModalProps {
  onClose: () => void;
  onCreate: (section: OfferSection) => void;
  allSections: OfferSection[];
}

export const CreateSectionModal: React.FC<CreateSectionModalProps> = (props) => {
  const { onClose, onCreate, allSections } = props;
  const { register, handleSubmit, control, reset } = useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    reset();
  }, [isModalOpen]);

  async function submit(data: OfferSection) {
    const d = await createSection(data.name, allSections?.length + 1);
    onCreate(d);
    setIsModalOpen(false);
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
        Sektion hinzufügen
      </Button>
      <Dialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          onClose();
        }}>
        <form className="flex flex-col p-3 space-y-3" onSubmit={handleSubmit(submit)}>
          Sektion hinzufügen
          <TextField {...register('name', { required: true })} id="outlined-basic" label="Name" variant="outlined" />
          <Button variant="contained" type="submit">
            Speichern
          </Button>
        </form>
      </Dialog>
    </>
  );
};
