import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { TitleBar } from '../components/adminLayout/TitleBar';
import { AdminLayout } from '../layouts/AdminLayout';
import { getControllingSummary } from '../services';

export interface ControllingEntry {
  livestreamCount: number;
  totalEncodeMinutes: number;
  totalStreamMinutes: number;
  encodeCostUsd: number;
  streamCostUsd: number;
  storageCostUsd: number;
  eventId: number;
  eventName: number;
  eventSlug: number;
  eventDate: Date;
  totalVideoConferenceMinutes: number;
  videoCostUsd: number;
  totalPrice: number;
}

export const ControllingPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ControllingEntry[]>([]);

  async function fetch() {
    setIsLoading(true);
    try {
      const d = await getControllingSummary();
      setData(d.data);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <AdminLayout>
      <TitleBar title="Controlling" actions={<></>} />
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Eventname</TableCell>
                  <TableCell>Slug</TableCell>
                  <TableCell>Stream Encoding Kosten ($)</TableCell>
                  <TableCell>Stream Encoding Minuten</TableCell>
                  <TableCell>Stream Delivery Kosten ($)</TableCell>
                  <TableCell>Stream Delivery Minuten</TableCell>
                  <TableCell>Stream Storage Kosten ($)</TableCell>
                  <TableCell>Video Kosten ($)</TableCell>
                  <TableCell>Video Minuten</TableCell>
                  <TableCell>Gesamt ($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.eventName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.eventSlug}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.encodeCostUsd?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.totalEncodeMinutes?.toFixed(2)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.streamCostUsd?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.totalStreamMinutes?.toFixed(2)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.storageCostUsd?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.videoCostUsd?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.totalVideoConferenceMinutes?.toFixed(2)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.totalPrice?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </AdminLayout>
  );
};
