import { Button, Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useState } from 'react';
import { getUsersByEmail, updateUserSalesAccess } from '../../services';
import { PartialUser } from '../../shared/interfaces/common.interface';

interface GrantSalesAccessProps {
  onClose: () => void;
}

export const GrantSalesAccess: React.FC<GrantSalesAccessProps> = (props) => {
  const { onClose } = props;

  const [users, setUsers] = useState<PartialUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');

  async function fetch() {
    setIsLoading(true);
    try {
      const rawData = await getUsersByEmail(currentEmail);
      setUsers(rawData);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  async function handleSearchButtonClick() {
    if (!currentEmail || !validEmail) return;
    await fetch();
  }

  async function handleAccessButtonClick(userId: string, hasSalesAccess: boolean) {
    setIsLoading(true);
    try {
      await updateUserSalesAccess({ userid: userId, hasSalesAccess });
      await fetch();
    } catch (err) {
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  const validEmail = currentEmail.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);

  return (
    <>
      <Button fullWidth variant="outlined" onClick={() => setIsModalOpen(true)}>
        Existierenden Nutzer hinzufügen
      </Button>
      <Dialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          onClose();
        }}>
        <div className="p-4">
          <div>
            <TextField variant="outlined" className="w-full" placeholder="E-Mail" value={currentEmail} onChange={(e) => setCurrentEmail(e.target.value)} />
            <Button disabled={!validEmail} className="w-full" onClick={() => handleSearchButtonClick()}>
              Finden
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">E-Mail</TableCell>
                  <TableCell align="right">Access</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.length > 0 &&
                  users.map((e) => (
                    <TableRow key={e._id}>
                      <TableCell component="th" scope="row">
                        {e.name}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {e.email}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        <Button
                          disabled={isLoading}
                          color={!e.hasSalesAccess ? 'primary' : 'error'}
                          onClick={() => handleAccessButtonClick(e._id, !e.hasSalesAccess)}>
                          {!e.hasSalesAccess ? 'Give Access' : 'Remove Access'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {!users || (users.length === 0 && <span className="p-2">Keine Ergebnisse</span>)}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Dialog>
    </>
  );
};
