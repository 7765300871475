import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { callsPerUserRecord, getCallsPerUser } from '../../services';

interface CallsPerUserProps {
  floatingDays: number;
}

export const CallsPerUser = (props: CallsPerUserProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>();

  async function fetchData() {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const dataCombined = [];
      const data: callsPerUserRecord[] = await getCallsPerUser({
        from: moment().subtract(props.floatingDays, 'days').startOf('day').toDate(),
        to: moment().endOf('day').toDate(),
      });
      const dataComp: callsPerUserRecord[] = await getCallsPerUser({
        from: moment()
          .subtract(2 * props.floatingDays, 'days')
          .startOf('day')
          .toDate(),
        to: moment().subtract(props.floatingDays, 'days').endOf('day').toDate(),
      });
      for (const el of data) {
        dataCombined.push({
          ...el,
          ...{
            callsComp: dataComp?.find((u: any) => u.userid === el.userid)?.calls,
          },
        });
      }
      setData(dataCombined);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card className="w-full mt-4 md:mt-0" elevation={3}>
      <CardContent>
        <Typography className="font-size: 1rem;line-height: 1.5rem;" color="textSecondary" gutterBottom>
          Anzahl Anrufe in den letzten {props.floatingDays} Tagen vgl. zu den letzten {props.floatingDays * 2} Tagen
        </Typography>
        <div className="flex flex-col w-full">
          {isLoading && (
            <div className="text-center m-4">
              <Skeleton animation="wave" height={60} width="100%" style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={60} width="100%" style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={60} width="100%" style={{ marginBottom: 6 }} />
            </div>
          )}
          {!isLoading && data && data.length !== 0 && (
            <div className="p-3">
              {' '}
              <ResponsiveContainer width="100%" aspect={2.0 / 0.75}>
                <BarChart data={data}>
                  <Bar stackId="a" dataKey="callsComp.withLength" name="Anrufe mit Länge Vergleichszeitraum" fill="#bfbfbf" />{' '}
                  <Bar stackId="a" dataKey="callsComp.withNoLength" name="Anrufe ohne Länge Vergleichszeitraum" fill="#dbdbdb" />
                  <Bar stackId="b" dataKey="calls.withLength" name="Anrufe mit Länge" fill="#4896f4" />
                  <Bar stackId="b" dataKey="calls.withNoLength" name="Anrufe ohne Länge" fill="#a8ccf7" />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="username" />
                  <YAxis />
                  <Tooltip cursor={false} />
                  <Tooltip />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
