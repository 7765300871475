import { Avatar } from '@mui/material';
import { PopulatedOffer } from '../../shared/interfaces/offer.interface';

interface OfferSummaryExpiredProps {
  offer?: PopulatedOffer;
}

export const OfferSummaryExpired: React.FC<OfferSummaryExpiredProps> = ({ offer }) => {
  const owner = offer?.hubspotMetaData?.owner;
  return (
    <div className="flex w-full h-screen justify-center items-center">
      <div className="flex flex-col p-4 border">
        <div>
          Das Angebot ist leider schon abgelaufen.{' '}
          {owner && (
            <>
              Bitte kontaktieren Sie<span className="font-bold"> {owner?.name}.</span>
            </>
          )}
        </div>
        {owner && (
          <div className="mt-2 flex">
            <Avatar src={`https://static.vystem.io/${owner.vystemUser?.avatar}`} />
            <div className="flex flex-col ml-2">
              <div>
                <span className="font-bold">{owner?.name}</span>
              </div>
              <div>
                <a href={`mailto:${owner?.email}`}>{owner?.email}</a>
              </div>
              <div>{owner?.phone}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
