import { MenuItem, Select } from '@mui/material';
import { Offer } from '../../shared/interfaces/offer.interface';

interface OfferFiltersProps {
  offers: Offer[];
  currentOwner: string;
  onChange: (text: string) => void;
}

export const OfferFilters: React.FC<OfferFiltersProps> = ({ offers, currentOwner, onChange }) => {
  const ownersList = offers
    .map((offer) => offer?.hubspotMetaData?.owner?.name)
    .filter((owner, index, currentOwners) => {
      if (currentOwners.indexOf(owner) == index) return owner;
    });

  return (
    <div className="flex items-center space-x-1">
      <span>Zuständige Mitarbeiter</span>
      <Select labelId="owner-select" id="owner-select-id" value={currentOwner} onChange={(e) => onChange(e.target.value.toString())} autoWidth label="Age">
        <MenuItem value="Alle Benutzer">Alle Benutzer</MenuItem>
        {ownersList.map((owner) => {
          if (owner) return <MenuItem value={owner}>{owner}</MenuItem>;
        })}
      </Select>
    </div>
  );
};
