import { OfferPosition, OfferTemplate } from '../../../shared/interfaces/offer.interface';

interface PricingCellProps {
  position: OfferPosition;
  templates: OfferTemplate[];
}

export const PricingCell: React.FC<PricingCellProps> = (props) => {
  const { position, templates } = props;

  if (position.pricingType !== 'variableTemplate' && position.pricingType !== 'variableTemplateFixPrice') return <>{position.price}</>;

  const pricingValues: {
    templateName: string;
    price: number;
  }[] = templates.map((template) => {
    const match = template.positions.find((pos) => pos.positionId === position._id);
    return { templateName: template.name, price: match?.templatePrice || 0 };
  });

  return (
    <div>
      {pricingValues.map((p) => (
        <div>
          <i>{p.templateName}</i>: {p.price}
        </div>
      ))}
    </div>
  );
};
