import { Button, Dialog, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { createTemplate, updateTemplate } from '../../../services';
import { OfferPosition, OfferSection, OfferTemplate } from '../../../shared/interfaces/offer.interface';
import { SectionTitle } from '../../offerbuilder/SectionTitle';
import { BooleanFormField } from '../../shared/offer/BooleanFormField';
import { NumberFormField } from '../../shared/offer/NumberFormField';

interface CreatePlanModalProps {
  editTemplate?: OfferTemplate;
  onClose: () => void;
  onCreate: (template: OfferTemplate) => void;
  offerPositions: OfferPosition[];
  offerSections: OfferSection[];
}

export const CreatePlanModal: React.FC<CreatePlanModalProps> = (props) => {
  const { onClose, onCreate, offerPositions, editTemplate, offerSections } = props;

  const { register, handleSubmit, control, reset } = useForm();

  const { fields } = useFieldArray({
    control,
    name: 'positions',
  });

  async function submit(data: any) {
    if (data._id) {
      await updateTemplate(data._id, data);
    } else {
      await createTemplate(data);
    }
    setIsModalOpen(false);
    onCreate(data);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    reset({
      _id: null,
      ...editTemplate,
      positions: offerPositions.map((o) => {
        /**
         * This supports adding offerpositions after the template has
         * been created
         */
        const matchingPosition = offerPositions.find((pos) => pos._id === o._id);
        const existing = editTemplate?.positions?.find((pos) => pos.positionId === o._id);

        return {
          positionId: o._id,
          ...matchingPosition,
          ...existing,
        };
      }),
    });
  }, [isModalOpen]);

  useEffect(() => {
    if (editTemplate) setIsModalOpen(true);
  }, [editTemplate]);

  return (
    <>
      <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
        Plantemplate erstellen
      </Button>
      <Dialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          onClose();
        }}>
        <div className="p-3 space-y-3">
          Plan hinzufügen
          <form className="flex flex-col space-y-3" onSubmit={handleSubmit(submit)}>
            <input {...register('_id')} hidden />
            <TextField {...register('name', { required: true })} id="outlined-basic" label="Name" variant="outlined" />
            <TextField {...register('description', { required: false })} id="outlined-basic" label="Beschreibung" variant="outlined" />
            {fields.map((field: any, index: number, fields: any[]) => {
              const matchingPosition = offerPositions.find((pos) => pos._id === field.positionId);
              if (!matchingPosition) return <></>;
              return (
                <div className="flex flex-col space-y-2" key={field.id}>
                  <SectionTitle fields={fields} currentIndex={index} currentFieldSection={field.sectionId} allSections={offerSections} />
                  {matchingPosition.label}
                  {matchingPosition.type === 'boolean' && <BooleanFormField control={control} label="" name={`positions.${index}.defaultChecked`} />}
                  {matchingPosition.type === 'number' && <NumberFormField control={control} label="" name={`positions.${index}.defaultValue`} />}
                  {matchingPosition.type === 'text' && (
                    <TextField {...register(`positions.${index}.defaultText`)} id="outlined-basic" label="Text" variant="outlined" />
                  )}
                  {matchingPosition.pricingType === 'variableTemplate' && (
                    <NumberFormField control={control} label="Templatepreis" name={`positions.${index}.templatePrice`} min="0" max="10000" />
                  )}
                  {matchingPosition.pricingType === 'variableTemplateFixPrice' && (
                    <NumberFormField control={control} label="Templatepreis" name={`positions.${index}.templatePrice`} min="0" max="10000" />
                  )}
                </div>
              );
            })}
            <Button variant="contained" type="submit">
              {editTemplate ? <>Speichern</> : <>Erstellen</>}
            </Button>
          </form>
        </div>
      </Dialog>
    </>
  );
};
