import { Alert } from '@mui/lab';
import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';

interface VyConfirmProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onConfirm?: () => void;
  onDeny?: () => void;
  title?: string;
  message?: string;
  confirmText?: string;
  warningText?: string;
  denyText?: string;
  disabled?: boolean;
  children: React.ReactFragment;
  className?: string;
  defaultOpen?: boolean;
  skipConfirmation?: boolean;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
};

export const VyConfirm = ({
  onClick,
  onConfirm,
  disabled,
  onDeny,
  children,
  confirmText = 'Ja',
  denyText = 'Nein',
  title = 'Fortführen?',
  message,
  warningText,
  className,
  defaultOpen = false,
  skipConfirmation = false,
}: VyConfirmProps) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <p className="text-left text-black text-xl font-bold mb-2">{title}</p>
          <p className="text-left">{message}</p>
          {warningText && <Alert severity="warning">{warningText}</Alert>}
          <div className="flex flex-row justify-between mt-5 space-x-2">
            <Button
              className="w-full"
              variant="outlined"
              onClick={() => {
                setOpen(false);
                onConfirm?.();
              }}>
              {confirmText}
            </Button>
            <Button
              className="w-full"
              variant="outlined"
              onClick={() => {
                setOpen(false);
                onDeny?.();
              }}>
              {denyText}
            </Button>
          </div>
        </Box>
      </Modal>
      <div
        className={`${disabled ? '' : 'cursor-pointer'} ${className ? className : ''}`}
        onClick={(e) => {
          if (disabled) {
            // do nothing on disabled
          } else {
            e.stopPropagation();
            if (skipConfirmation) {
              onConfirm?.();
              return;
            }
            setOpen(true);
            onClick?.(e);
          }
        }}>
        {children}
      </div>
    </>
  );
};
