import { Button, Dialog, TextField } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createUser } from '../../services';
import { PartialUser } from '../../shared/interfaces/common.interface';

interface AddSalesAccessProps {
  onClose: () => void;
}

export const AddSalesAccess: React.FC<AddSalesAccessProps> = (props) => {
  const { onClose } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');

  const { handleSubmit, register, reset } = useForm();

  async function handleCreate(props: PartialUser) {
    try {
      setError('');
      await createUser({ ...props, role: 'ORGANIZATION_SUBUSER', hasSalesAccess: true });
      reset();
      setIsModalOpen(false);
      onClose();
    } catch (err) {
      setError('Etwas ist schief gelaufen !');
    }
  }

  return (
    <>
      <Button fullWidth variant="outlined" onClick={() => setIsModalOpen(true)}>
        Nutzer hinzufügen
      </Button>
      <Dialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          onClose();
        }}>
        <div className="p-4">
          <span> Nutzer hinzufügen</span>
          <form onSubmit={handleSubmit(handleCreate)} className="w-full flex flex-col space-y-2">
            <TextField variant="outlined" placeholder="Name" className="w-full" {...register('name', { required: true, minLength: 2 })} />
            <TextField
              variant="outlined"
              className="w-full"
              placeholder="E-Mail"
              {...register('email', { required: true, pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/ })}
            />
            <Button className="w-full" type="submit">
              Neuen Nutzer erstellen
            </Button>
            {error && <span className="text-red-600">{error}</span>}
          </form>
        </div>
      </Dialog>
    </>
  );
};
