import { useEffect, useMemo, useState } from 'react';
import { getPayoutRequests } from '../../services';
import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { PayoutRequest } from '../../shared/interfaces/common.interface';
import { format } from 'date-fns';
import { Edit } from '@mui/icons-material';
import { PayoutTableRow } from './PayoutTableRow';

const ROW_COUNT = 25;

export const PayoutTable = () => {
  const [payoutRequests, setPayoutRequests] = useState<PayoutRequest[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(-1);

  const filteredRows = useMemo(() => payoutRequests.slice(ROW_COUNT * page, ROW_COUNT * page + ROW_COUNT), [page]);

  async function fetchPayoutRequests() {
    setLoading(true);
    try {
      const data = await getPayoutRequests();
      setPayoutRequests(data.data);
      setPage(0);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchPayoutRequests();
  }, []);

  return (
    <div>
      {loading && <LinearProgress />}
      <TableContainer>
        <Table className="offer-table template-border">
          <TableHead>
            <TableRow>
              <TableCell style={{ flex: 1 }}>Datum</TableCell>
              <TableCell style={{ flex: 1 }}>Event</TableCell>
              <TableCell style={{ flex: 1 }}>Menge</TableCell>
              <TableCell style={{ flex: 1 }}>Status</TableCell>
              <TableCell style={{ flex: 1 }}>Feedback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows?.map((payoutRequest) => (
              <PayoutTableRow payoutRequest={payoutRequest} />
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <TablePagination count={payoutRequests.length} onPageChange={(e, page) => setPage(page)} page={page} rowsPerPage={25}></TablePagination>
        </TableFooter>
      </TableContainer>
    </div>
  );
};
