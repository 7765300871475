import CircleIcon from '@mui/icons-material/Circle';
import { LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useOfferData } from '../../hooks/useOfferData';
import { updateOffer } from '../../services';
import { Offer, OfferStatus } from '../../shared/interfaces/offer.interface';
import { VyConfirm } from '../common/VyConfirm';

interface OfferStatusCellProps {
  offer: Offer;
  onStatusChange: (newStatus: OfferStatus) => void;
}

interface StatusItem {
  level: number;
  status: OfferStatus;
  color: string;
  text: string;
}

export const OfferStatusCell: React.FC<OfferStatusCellProps> = ({ offer, onStatusChange }) => {
  const { isOfferExpired } = useOfferData();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isBusy, setIsBusy] = useState(false);

  const { addToast } = useToasts();

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!offer || !offer.status) return <>Kein Status</>;

  const open = Boolean(anchorEl);
  const id = open ? 'status-popover' : undefined;

  const isExpired = isOfferExpired(offer);
  const status = offer.status;

  const statusItems: StatusItem[] = [
    {
      level: 0,
      status: 'draft',
      color: 'text-gray-500',
      text: 'Entwurf',
    },
    {
      level: 1,
      status: 'published',
      color: 'text-green-500',
      text: 'Veröffentlicht',
    },
    {
      level: 2,
      status: 'rejected',
      color: 'text-red-500',
      text: 'Abgelehnt',
    },
    {
      level: 2,
      status: 'accepted',
      color: 'text-green-700',
      text: 'Akzeptiert',
    },
  ];

  function getColorForStatus() {
    if (isExpired) return 'text-yellow-500';

    const item = statusItems.find((i) => i.status === status);
    return item?.color;
  }

  function getTextForStatus() {
    if (isExpired) return 'Abgelaufen';
    const item = statusItems.find((i) => i.status === status);
    return item?.text || 'Kein Status';
  }

  function getCurrentLevel() {
    if (isExpired) return 5;

    const item = statusItems.find((i) => i.status === status);
    return item?.level || 0;
  }

  async function changeStatus(status: OfferStatus) {
    if (!offer?._id) return;
    setIsBusy(true);
    try {
      updateOffer(offer._id, { status: status });
      addToast('Erfolg', { appearance: 'success' });
      onStatusChange(status);
    } catch (err) {
      console.error(err);
      addToast('Es ist ein Fehler aufgetreten', { appearance: 'error' });
    }
    setIsBusy(false);
  }

  return (
    <>
      <div className="flex justify-between items-center w-full cursor-pointer" onClick={(e) => handleClick(e)}>
        <CircleIcon style={{ width: '15px', height: '15px' }} className={getColorForStatus()} />
        <span className="ml-2 mr-4">{getTextForStatus()}</span>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        {isBusy && <LinearProgress />}
        <List className="template-border text-sm">
          {statusItems.map((item) => {
            const disabled = (item.level === 0 && getCurrentLevel() > 0) || offer.status === item.status || isBusy;
            return (
              <VyConfirm
                message={
                  offer.status === 'draft'
                    ? 'Es können keine Änderungen mehr vorgenommen werden. Für Änderungen muss das Angebot dupliziert und angepasst werden.'
                    : ''
                }
                disabled={disabled}
                onConfirm={() => changeStatus(item.status)}>
                <ListItem disablePadding>
                  <ListItemButton disabled={disabled}>
                    <ListItemIcon>
                      <CircleIcon style={{ width: '15px', height: '15px' }} className={item.color} />
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              </VyConfirm>
            );
          })}
        </List>
      </Popover>
    </>
  );
};
