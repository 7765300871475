import { PopulatedOffer } from '../../shared/interfaces/offer.interface';
import { CompanyInfo } from './CompanyInfo';
import { OfferMetaData } from './OfferMetaData';

interface OfferSummaryHeaderProps {
  offer?: PopulatedOffer;
}

export const OfferSummaryHeader: React.FC<OfferSummaryHeaderProps> = ({ offer }) => {
  return (
    <div className="flex justify-center w-full bg-blue-400 print:text-black text-white pt-8 pb-8">
      <div style={{ width: '210mm' }} className="flex flex-col w-full">
        <span className="text-4xl font-bold">{offer?.name}</span>
        <div className="flex mt-4 justify-between">
          <CompanyInfo offer={offer} />
          <OfferMetaData offer={offer} />
        </div>
      </div>
    </div>
  );
};
