import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VyLoader } from '../components/common/VyLoader';
import { removeToken } from '../helpers/auth';
import { useAuth } from '../hooks/useAuth';
import { getAuth } from '../services';

interface AuthWrapperProps {
  redirectToDashboard?: boolean;
  redirectToLogin?: boolean;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = (props) => {
  const { redirectToDashboard, redirectToLogin = true } = props;

  const navigate = useNavigate();
  const { checkAuthFromLocalStorage } = useAuth();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuthAndToken();
  }, []);

  async function checkAuthAndToken() {
    try {
      await getAuth();
    } catch (err) {
      removeToken();
      setIsLoading(false);
      return navigate('/login');
    }
    const auth = checkAuthFromLocalStorage();
    if (!auth && redirectToLogin) return navigate('/login');
    if (auth && redirectToDashboard) return navigate('/dashboard');
    setIsLoading(false);
  }

  if (isLoading) return <VyLoader solidBackground={true} />;

  return <>{props.children}</>;
};
