import { PopulatedOffer } from '../../shared/interfaces/offer.interface';

interface OfferSummaryCommentProps {
  offer?: PopulatedOffer;
}

export const OfferSummaryComment: React.FC<OfferSummaryCommentProps> = ({ offer }) => {
  if (!offer || !offer.description) return null;

  const name = offer?.hubspotMetaData?.owner?.name;

  return (
    <div className="flex flex-col w-full p-8 border-2 border-blue-400 mt-8">
      <span className="font-bold mb-4 summary-title">Kommentar{name ? ` von ${name}` : ``}</span>
      <div className="mb-4 summary-description" dangerouslySetInnerHTML={{ __html: offer.description }}></div>
    </div>
  );
};
