import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { requestSign, signOffer } from '../../services';
import { PopulatedOffer } from '../../shared/interfaces/offer.interface';

interface ClickAndSignProps {
  offer: PopulatedOffer;
  open: boolean;
  onClose?: () => void;
}

export const ClickAndSign: React.FC<ClickAndSignProps> = (props) => {
  const { offer, open = false, onClose } = props;

  // screen 1 is request code screen , screen 2 is sign screen where you validate code
  const [isSignScreen, setIsSignScreen] = useState(false);
  const [submittedNumber, setSubmittedNumber] = useState('');
  const [isError, setIsError] = useState(false);
  const {
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { isSubmitting },
  } = useForm({ mode: 'onSubmit' });
  const {
    handleSubmit: handleSubmitSign,
    watch: watchSign,
    register: registerSign,
    formState: { isSubmitting: isSignSubmitting },
  } = useForm({ mode: 'onSubmit' });

  const watchCountryCode = watch('countryCode');
  const watchOtp = watchSign('otp');

  useEffect(() => {
    if (!watchCountryCode || watchCountryCode[0] !== '+') setValue('countryCode', `+${watchCountryCode || ''}`);
  }, [watchCountryCode]);

  async function submitRequest(props: { countryCode: string; number: string }) {
    if (!offer || !offer._id) return;
    const phonenumber = props.countryCode + props.number;
    try {
      await requestSign({ offerId: offer._id, phonenumber: phonenumber });
    } catch (err) {
      setIsError(true);
      return;
    }
    setIsError(false);
    setIsSignScreen(true);
    setSubmittedNumber(phonenumber);
  }

  async function submitSign(props: { otp: string }) {
    if (!offer || !offer._id) return;
    try {
      await signOffer({ offerId: offer._id, otp: parseInt(props.otp) });
    } catch (err) {
      setIsError(true);
      return;
    }
    setIsError(false);
    onClose?.();
  }

  const renderTexts = {
    title: isSignScreen ? 'Geben Sie den Bestätigungscode ein' : 'Bestätigen Sie Ihre Nummer',
    description: isSignScreen
      ? `Wir haben einen 6-stelligen Code an ${submittedNumber} gesendet. Bitte geben Sie den soeben erhaltenen Code ein.`
      : 'Bitte geben Sie Ihre Telefonnummer ein, um einen Verifizierungscode zu erhalten',
    buttonText: isSignScreen ? 'Bestätigen' : 'Weiter',
    cancelText: isSignScreen ? 'Bestätigungscode erneut senden' : 'Nein, danke',
    errorText: isSignScreen ? 'Falscher Code, versuchen Sie es erneut.' : 'Etwas ist schief gelaufen, bitte überprüfen Sie, ob Ihre Nummer korrekt ist.',
  };

  return (
    <Dialog open={open} onClose={() => onClose?.()} keepMounted={false}>
      <DialogTitle id="alert-dialog-title" className="text-center">
        Click&Sign
      </DialogTitle>
      <DialogContent className="w-80">
        <div className="flex flex-col items-center text-center text-sm">
          <img src="/phone-verification.jpeg" />
          <span className="font-bold text-xl mt-4 mb-2">{renderTexts.title}</span>
          <p className="text-center ">{renderTexts.description}</p>
          {!isSignScreen ? (
            <form key="request-form" onSubmit={handleSubmit(submitRequest)} className="flex flex-col items-center">
              <div className="flex space-x-2 mt-2 mb-2">
                <TextField
                  id="country-code"
                  inputProps={{ maxLength: 4 }}
                  {...register('countryCode', { required: true, maxLength: 4, pattern: /^\+?(\d{1,3})$/ })}
                  className="w-24"
                  disabled={isSubmitting}
                />
                <TextField id="number-input" {...register('number', { required: true, pattern: /[0-9]/ })} disabled={isSubmitting} />
              </div>
              <Button className="vystem-button" disabled={isSubmitting} variant="contained" type="submit">
                {renderTexts.buttonText}
              </Button>
            </form>
          ) : (
            <form key="sign-form" onSubmit={handleSubmitSign(submitSign)} className="flex flex-col items-center">
              <div className="flex space-x-2 mt-2 mb-2">
                <TextField
                  id="otp"
                  placeholder="6-stelliger Code"
                  type="number"
                  inputProps={{ max: 999999, maxLength: 6 }}
                  {...registerSign('otp', { required: true, max: 999999, maxLength: 6 })}
                  fullWidth
                  disabled={isSignSubmitting}
                />
              </div>
              <Button className="vystem-button" disabled={watchOtp?.length !== 6 || isSignSubmitting} variant="contained" type="submit">
                {renderTexts.buttonText}
              </Button>
            </form>
          )}
          {isError && <span className="text-red-500 m-2">{renderTexts.errorText}</span>}
          <span
            onClick={() => {
              isSignScreen ? setIsSignScreen(false) : onClose?.();
              setIsError(false);
            }}
            className="cursor-pointer text-gray-400 mt-2">
            {renderTexts.cancelText}
          </span>
          {(isSubmitting || isSignSubmitting) && <CircularProgress className="m-1" />}
        </div>
      </DialogContent>
    </Dialog>
  );
};
