import { makeStyles } from '@mui/styles';
import React from 'react';
import { RingLoader, ScaleLoader } from 'react-spinners';

/**
 * @params solidBackground for not having any transparancy in the loader
 * @params backgroundColor the color in the background of the loader
 * @params loaderColor the color of the spinner loader
 * @params redirectUri if specified a button to directly redirect is shown below the spinner
 */
interface VyLoaderProps {
  solidBackground?: boolean;
  backgroundColor?: string;
  loaderColor?: string;
  redirectUri?: string;
  useRingLoader?: boolean;
}

const useStyles = makeStyles(() => ({
  loadingBackground: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    zIndex: 1300,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
  },
}));

export const VyLoader: React.FC<VyLoaderProps> = (props) => {
  const { loaderColor, backgroundColor, solidBackground, useRingLoader } = props;

  const classes = useStyles();

  return (
    <div
      className={classes.loadingBackground}
      style={{
        backgroundColor: solidBackground ? backgroundColor || 'white' : 'rgba(0, 0, 0, 0.5)',
      }}>
      {!useRingLoader ? <ScaleLoader color={loaderColor} /> : <RingLoader color={loaderColor} />}
    </div>
  );
};
