import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CalculatedPosition, TotalPrice } from '../../hooks/useOfferCalculation';
import { OfferSection, PopulatedOffer } from '../../shared/interfaces/offer.interface';
import { EuroDisplay } from '../shared/offer/EuroDisplay';

interface OfferSummaryTableProps {
  calculatedPositions?: CalculatedPosition[];
  offerSections?: OfferSection[];
  totalPrice: TotalPrice;
  offer?: Partial<PopulatedOffer>;
  detailView?: boolean;
  color?: string;
}

const useStyles = makeStyles(() => ({
  body: {
    '& > tr': {
      '& > td': {
        color: (props: { color?: string }) => props.color,
      },
    },
  },
  row: {
    '& > td': {
      borderBottom: 'none',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    display: 'table-row-group !important',
  },
  rowTopBorder: {
    '& > td': {
      borderTop: '1px solid rgba(224, 224, 224, 1);',
    },
  },
  white: {
    color: 'white',
  },
}));

export const OfferSummaryTable: React.FC<OfferSummaryTableProps> = ({ calculatedPositions, totalPrice, offer, offerSections, detailView = false, color }) => {
  const classes = useStyles({ color: color });
  const showPricesPerPosition = detailView || offer?.showPricesPerPosition;
  return (
    <div className="flex flex-col w-full mt-8 mb-8 print:h-full" style={{ pageBreakInside: 'auto' }}>
      <span className="font-bold mb-4">Produkte & Services</span>
      <div className="block" style={{ overflow: 'scroll' }}>
        <Table stickyHeader={false} aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Artikel & Beschreibung</TableCell>
              {showPricesPerPosition && <TableCell align="right">Anzahl</TableCell>}
              <TableCell align="right">Einzelpreis</TableCell>
              <TableCell align="right">Gesamt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {offer?.template?.name && (
              <>
                <TableRow className={classes.row} key={`template-row`}>
                  <TableCell colSpan={showPricesPerPosition ? 2 : 1}>
                    <div className="mb-2 font-bold">Paket: {offer?.template?.name}</div>
                  </TableCell>

                  <TableCell style={{ verticalAlign: 'top' }} align="right">
                    <EuroDisplay value={totalPrice?.templatePrice} />
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top' }} align="right">
                    <EuroDisplay value={totalPrice?.templatePrice} />
                  </TableCell>
                </TableRow>
                {offerSections
                  ?.filter((section) => !offer?.hiddenSections?.includes(section._id))
                  .map((section, sectionIndex) => {
                    const matchingPositions =
                      calculatedPositions
                        ?.filter((pos) => pos.sectionId === section._id)
                        .filter((pos) => pos.type !== 'boolean' || pos.value === true)
                        .filter((pos) => pos.type !== 'number' || (pos.value && pos?.value > 0)) || [];
                    if (matchingPositions.length === 0) return <></>;

                    return (
                      <>
                        <TableRow className={classes.row} key={`section_title_${sectionIndex}`}>
                          <TableCell colSpan={showPricesPerPosition ? 4 : 3}>
                            <b>{section.name}</b>
                          </TableCell>
                        </TableRow>

                        {matchingPositions.map((pos, index) => (
                          <TableRow className={classes.row} key={`row_${index}`} style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                            <TableCell>
                              {pos.title}
                              {pos.quantity && !showPricesPerPosition ? `: ${pos.quantity?.toLocaleString()}x` : ''}
                              {pos.type === 'text' ? `: ${pos.value}` : ``}
                              {pos.eventFactor && pos.quantity && showPricesPerPosition && (
                                <>
                                  <br />
                                  <Tooltip title={`(Teilnehmerzahl * ${pos.quantity} * ${pos.singleUnitPrice}) / ${pos.eventFactor}`}>
                                    <i>Berechnung</i>
                                  </Tooltip>
                                </>
                              )}
                            </TableCell>
                            {showPricesPerPosition && (
                              <>
                                <TableCell align="right">{pos.quantity?.toLocaleString()}</TableCell>
                                <TableCell align="right">{pos.singleUnitPrice ? <EuroDisplay value={pos.singleUnitPrice} /> : null}</TableCell>
                                <TableCell align="right">{pos.totalPrice ? <EuroDisplay value={pos.totalPrice} /> : null}</TableCell>
                              </>
                            )}
                          </TableRow>
                        ))}
                      </>
                    );
                  })}

                {offer?.customTemplateText && (
                  <TableRow style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                    <TableCell colSpan={showPricesPerPosition ? 4 : 3}>
                      <div dangerouslySetInnerHTML={{ __html: offer?.customTemplateText || '' }}></div>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
            {offer?.customPositions?.map((pos, index) => (
              <TableRow
                key={`custom-position-${index}`}
                className={index === 0 ? classes.rowTopBorder : ''}
                style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                <TableCell colSpan={showPricesPerPosition ? 2 : 1}>
                  <div dangerouslySetInnerHTML={{ __html: pos.positionText }} />
                </TableCell>
                <TableCell style={{ verticalAlign: 'top' }} align="right">
                  <EuroDisplay value={pos.price * (pos.isDiscount ? -1 : 1)} />
                </TableCell>
                <TableCell style={{ verticalAlign: 'top' }} align="right">
                  <EuroDisplay value={pos.price * (pos.isDiscount ? -1 : 1)} />
                </TableCell>
              </TableRow>
            ))}
            <TableRow key="total-price-yearly" style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
              <TableCell colSpan={showPricesPerPosition ? 3 : 2} style={{ verticalAlign: 'top' }} align="right">
                Preis{offer?.showMonthlyPayment && 'e EZ( 15% sparen)'}
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }} align="right">
                <EuroDisplay value={offer?.showMonthlyPayment ? totalPrice.yearlyContractPrice : totalPrice.totalPrice} />
              </TableCell>
            </TableRow>
            {offer?.showMonthlyPayment && (
              <TableRow key="total-price-monthly" style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                <TableCell colSpan={showPricesPerPosition ? 3 : 2} style={{ verticalAlign: 'top' }} align="right">
                  Monatliche Zahlweise (12 Monate)
                </TableCell>
                <TableCell style={{ verticalAlign: 'top' }} align="right">
                  <EuroDisplay value={totalPrice.monthlyContractPrice} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
