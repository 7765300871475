import { CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { getAllUsersWithSalesAccess } from '../../services';
import { PartialUser } from '../../shared/interfaces/common.interface';
import { AddSalesAccess } from './AddSalesAccess';
import { GrantSalesAccess } from './GrantSalesAccess';

export const SalesAccessUserTable: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PartialUser[]>([]);

  async function fetch() {
    setIsLoading(true);
    try {
      const rawData = await getAllUsersWithSalesAccess();
      setData(rawData);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <div className="flex w-full space-x-2">
        <AddSalesAccess onClose={fetch} />
        <GrantSalesAccess onClose={fetch} />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">E-Mail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((e: any) => (
              <TableRow key={'1'}>
                <TableCell component="th" scope="row">
                  {e.name}
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  {e.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
