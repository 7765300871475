interface EuroDisplayProps {
  value?: number | null;
}

export const EuroDisplay: React.FC<EuroDisplayProps> = (props) => {
  const { value } = props;

  if (typeof value !== 'number') return <></>;

  return (
    <>
      {value.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })}
    </>
  );
};
